import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PathwaysService } from '../../services/pathways.service';
import { Pathways } from '../../models/pathways';
import { PagedList } from '../../../shared';
import { LookupService } from '../../../core/services/lookup.service';
import { Role, Tag } from '../../../core';
import { Subscription } from 'rxjs';
import { ValidationService } from '../../../core/services/validation.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'tga-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})
export class VideosComponent implements OnInit {


  videoDetail: any = {}; // To store videos
  totalVideoSeconds: number = 0; // To store total video seconds
  videoId: string = ""; // To store video id from the route
  startTime = {}; // To store start time
  endTime = {}; // To store end time
  startTimeErrorMessage: string = ""; // To store start time error message 
  endTimeErrorMessage: string = ""; // To store end time error message



  videoForm: FormGroup; // To represent video form
  isVideoFormSubmitted: boolean = false; // To store status of video form submission
  videoTypes: string[] = ['ACADEMIC', 'ENTERTAINMENT', 'GLOBAL']; // To store video types
  pathways: Pathways[] = []; // To store pathways
  tags: Tag[] = []; // To store tags
  videoFormValidationMessages = { // To store pathway form validation
    'pathwayId': {
      'required': 'Pathway should be selected.',
    },
    'videoType': {
      'required': 'Type should be selected'
    },
    'youtubeVideoId': {
      'required': 'Youtube Video should not be empty.'
    },
    'title': {
      'required': 'Title should not be empty.'
    },
    'order': {
      'required': 'Order should not be empty.'
    },
    'description': {
      'required': 'Description should not be empty.'
    },
    'tagId': {
      'required': 'Tag should be selected.'
    }
  };
  videoFormErrors = {
    pathwayId: '',
    videoType: '',
    youtubeVideoId: '',
    title: '',
    order: '',
    description: '',
    tagId: ''
  }; // To store errors
  videoFormValueChangesSubscription: Subscription;
  hideVideoForm: boolean = true; // To hide show video form
  videos: any[]; // To store videos
  page: number = 1; // To store current page
  limit: number = 20; // To store current page size, default 20
  totalCount: number; // To store total users list count
  search: string = ''; // To store search text
  // To store comment types
  commentTypes = [
    { name: 'Discuss', value: 'DISCUSS', img: 'discuss-dropdown.svg' },
    { name: 'Question', value: 'QUESTION', img: 'question-dropdown.svg' },
    { name: 'Suggest', value: 'SUGGESTION', img: 'suggest-dropdown.svg' },
    { name: 'Recommend a Resource', value: 'RECOMMEND', img: 'resource-dropdown.svg' },
  ];
  commentForm: FormGroup; // To represent comment form
  videoToAddComment: any = {}; // To store video detail for adding comment
  isCommentFormSubmitted: boolean = false; // To check whether comment form is submitted or not
  commentModalRef: NgbModalRef;
  curators = [] // To store curators;
  constructor(
    private formBuilder: FormBuilder,
    private pathwayService: PathwaysService,
    private lookupService: LookupService,
    private _validation: ValidationService,
    private modalService: NgbModal,
    private toasterService: ToastrService,
  ) { }

  ngOnInit() {
    // build video form
    this.buildVideoForm();
    // Get pathways
    this.getPathways();
    // Get tags
    this.getTags();
    // Get curators
    this.getCurators();
    // Get videos list
    this.getVideos(this.limit, this.page);
    this.buildCommentForm(); // Build comment form
  }

  /**
  * Get video detail
  * @param limit limit to be displayed records
  * @param page page for which page users list
  * @param search search to be filtered
  */
  getVideoDetail(videoId) {
    this.pathwayService.getVideoDetail(videoId).subscribe((data: PagedList<any[]>) => {
      this.videoDetail = data.data[0];
      this.totalVideoSeconds = this.convertTimeToSecond(this.videoDetail.duration);
    });
  }

  /**
  * Get Curators
  */
  getCurators() {
    this.lookupService.getUsers(Role.CURATOR).subscribe((data: any) => {
      this.curators = data.data;
    });
  }

  /**
   * Convert time to second
   * @param time time to be converted
   */
  convertTimeToSecond(time: any) {
    let timeInString = `${time.hour}:${time.minute}:${time.second}`;
    let splitTime = timeInString.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var seconds = (+splitTime[0]) * 60 * 60 + (+splitTime[1]) * 60 + (+splitTime[2]);
    return seconds;
  }

  /**
 * Convert second to hms
 * @param second
 */
  convertSecondToHMS(second: number) {
    second = Number(second);
    let hour = Math.floor(second / 3600);
    let minute = Math.floor(second % 3600 / 60);
    let secondTime = Math.floor(second % 3600 % 60);
    return { hour, minute, second: secondTime };
  }

  // Build video form
  buildVideoForm() {
    this.videoForm = this.formBuilder.group({
      _id: [null],
      pathwayId: [null, Validators.required],
      videoType: [null, Validators.required],
      youtubeVideoId: ['', Validators.required],
      title: ['', Validators.required],
      order: ['', Validators.required],
      description: ['', Validators.required],
      thumbnail: this.formBuilder.group({
        smallURL: [''],
        mediumURL: ['']
      }),
      duration: this.formBuilder.group({
        hour: [0],
        minute: [0],
        second: [0]
      }),
      tagId: [null, Validators.required],
      startTime: [null],
      endTime: [null],
      startTimeInSecond: [null],
      endTimeInSecond: [null],
    });

    this.getValidationErrors();

    // Get validation error when video form values get changed
    this.videoFormValueChangesSubscription = this.videoForm.valueChanges.subscribe(() => this.getValidationErrors());
  }

  /**
   * Build comment form
   */
  buildCommentForm() {
    this.commentForm = this.formBuilder.group({
      videoId: [null, Validators.required],
      text: ['', [Validators.required]],
      type: [this.commentTypes[0].value, Validators.required],
      pathwayId: ['', Validators.required],
      userId: [null],
      startTime: [null],
      timeSlot: [null],
    });
  }

  // Get validation errors
  getValidationErrors() {
    this.videoFormErrors = this._validation.getValidationErrors(this.videoForm, this.videoFormValidationMessages);
  }

  /**
   * Get pathways list
   */
  getPathways() {
    // Get pathway list by using pathway service
    this.pathwayService.getPathwaysList(20, 1).subscribe((res: PagedList<Pathways[]>) => {
      this.pathways = res.data;
    });
  }

  /**
   * Get tags
   */
  getTags() {
    this.lookupService.getTags().subscribe((data: PagedList<Tag[]>) => {
      this.tags = data.data;
    });
  }

  /**
   * Get videos list
   * @param limit limit to be displayed records
   * @param page page for which page users list
   * @param search search to be filtered
   */
  getVideos(limit: number, page: number, search: string = "") {
    this.pathwayService.getVideosList(limit, page, search).subscribe((data: PagedList<any[]>) => {
      this.videos = data.data;
      this.totalCount = data.totalCount;
    });
  }

  /**
   * Called when performed search
   * @param search search to be filtered
   */
  onSearchSubmit(search: string) {
    this.getVideos(this.limit, this.page, search);
  }

  /**
   * Show video detail into form
   * @param video video to be updated
   */
  showVideoDetailToForm(video) {
    // Bind video detail in form
    this.videoForm.patchValue({
      _id: video._id,
      pathwayId: video.pathwayId,
      youtubeVideoId: video.youtubeVideoId,
      title: video.title,
      description: video.description,
      thumbnail: video.thumbnail,
      duration: video.duration,
      tagId: video.tagId,
      order: video.order,
      videoType: video.videoType,
      startTime: video.startTime,
      endTime: video.endTime
    });
    this.hideVideoForm = false;
    window.scroll(0, 0); // Scroll to top
  }

  /**
   * Delete video
   * @param video video to be deleted
   */
  deleteVideo(video) {
    // Get confirmation from user about deletion of video
    if (confirm(`Are you sure you want to delete '${video.title}' video?`)) {
      // Delete video
      this.pathwayService.deleteVideo(video._id).subscribe(() => {
        // Get videos list after deleteing current video
        this.getVideos(this.limit, this.page);
      });
    }
  }

  /**
   * Called when page index is changed
   * @param page page to be changed
   */
  onPaginationChange(page: number) {
    this.getVideos(this.limit, page, this.search);
  }

  /**
   * Get youtube video detail
   */
  getYoutubeVideoDetail() {
    this.pathwayService.getYoutubeVideoDetail(this.videoForm.value.youtubeVideoId).subscribe((data: any) => {
      let title, description = "";
      let duration = { hour: 0, minute: 0, second: 0 };
      let thumbnail = { smallURL: '', mediumURL: '' };
      // Check video detail available or not
      if (data.items.length) {
        const [video] = data.items; // To get first video detail
        const { snippet, contentDetails } = video; // To get snippet and content details
        const { thumbnails } = snippet; // To get thumbnail details

        // Convert youtube duration to separate hour, minute and second
        duration = this.youtubeDurationToHourMinuteSecond(contentDetails.duration);
        title = snippet.title;
        description = snippet.description;
        thumbnail = { smallURL: thumbnails.default.url, mediumURL: thumbnails.medium.url }
      }
      // Bind fetched detail(title, description, duration and thumbnail) into vidoe form to display
      this.videoForm.patchValue({
        title: title,
        description: description,
        duration: duration,
        thumbnail: thumbnail
      });
    });
  }

  /**
   * Convert youtube duration to hour, minute and second
   * @param duration
   */
  youtubeDurationToHourMinuteSecond(duration) {
    // Format to store video duration
    let videoDuration = { hour: 0, minute: 0, second: 0 };

    // Remove PT text from the youtube duration
    duration = duration.replace("PT", '');

    // Get index of hour duration from youtube duration, if available then store it in hour
    let hourIndex = duration.indexOf('H');
    if (hourIndex != -1) {
      videoDuration.hour = parseInt(duration.substring(0, hourIndex));
    }

    // Get index of minute duration from youtube duration, if available then store it in minute
    let minuteIndex = duration.indexOf('M');
    if (minuteIndex != -1) {
      videoDuration.minute = parseInt(duration.substring(hourIndex + 1, minuteIndex));
    }

    // Get index of second duration from youtube duration, if available then store it in second
    let secondIndex = duration.indexOf('S');
    if (secondIndex != -1) {
      videoDuration.second = parseInt(duration.substring(minuteIndex + 1, secondIndex));
    }

    // Return video duration
    return videoDuration;
  }

  /**
   * Called when cancel button gets clicked
   */
  onCancelButtonClick() {
    this.resetVideoForm();
  }

  /**
   * Reset video form
   */
  resetVideoForm() {
    this.videoForm.reset();
    this.videoForm.patchValue({
      duration: { hour: 0, minute: 0, second: 0 }
    })
    this.isVideoFormSubmitted = false;
    this.hideVideoForm = true;
  }

  /**
   * set default starttime
   * @returns 0 when user not entered starttime
   */
  startTimeValue() {
    this.videoForm.patchValue({
      startTime: {
        hour: 0,
        minute: 0,
        second: 0
      }
    });
    return 0;
  }

  /**
   * set default video endtime
   * @returns total video seconds
   */
  endTimeValue() {
    this.videoForm.patchValue({
      endTime: this.videoForm.value.duration
    });
    return this.totalVideoSeconds;
  }

  /**
   * Called when video form gets submitted
   * @param isVideoFormValid isVideoFormValid to check whether video form is valid or not
   */
  onSubmit(isVideoFormValid: boolean) {
    this.isVideoFormSubmitted = true; // Indicate that form is submitted
    const { startTime, endTime } = this.videoForm.value;

    //  let endTime:{}=this.videoForm.value.endTime;
    let startTimeInSecond;
    let endTimeInSecond;
    let isValidate: boolean = true;
    this.totalVideoSeconds = this.convertTimeToSecond(this.videoForm.value.duration);

    if (startTime) {
      startTimeInSecond = this.convertTimeToSecond(startTime);
      if (isNaN(startTimeInSecond)) {
        startTimeInSecond = this.startTimeValue();
        // this.startTimeErrorMessage = "Start time should not be empty.";
        // isValidate = false;
      }
      if (startTimeInSecond >= this.totalVideoSeconds) {
        this.startTimeErrorMessage = "Start time should not be bigger that video duration.";
        isValidate = false;
      }
    } else {
      startTimeInSecond = this.startTimeValue();
    }

    if (endTime) {
      // endTimeInSecond = this.convertTimeToSecond(endTime);         
      endTimeInSecond = this.convertTimeToSecond(endTime);
      if (isNaN(endTimeInSecond)) {
        endTimeInSecond = this.endTimeValue();
        // this.endTimeErrorMessage = "End time should not be empty.";
        // isValidate = false;
      }
      if (endTimeInSecond > this.totalVideoSeconds) {
        this.endTimeErrorMessage = "End time should not be bigger than video duration.";
        isValidate = false;
      }
    } else {
      endTimeInSecond = this.endTimeValue();
    }

    if (!startTime && !endTime) {
      startTimeInSecond = this.startTimeValue();
      endTimeInSecond = this.endTimeValue();
    }

    this.videoForm.value.startTimeInSecond = startTimeInSecond;
    this.videoForm.value.endTimeInSecond = endTimeInSecond;
    if (isValidate) {
      if (startTimeInSecond >= endTimeInSecond) {
        this.toasterService.error("Start time should not be bigger than End time");
        isValidate = false;
      }
    }
    if (isValidate) {
      if (isVideoFormValid) {
        this.pathwayService.saveVideo(this.videoForm.value).subscribe((data: any) => {
          this.resetVideoForm();
          this.getVideos(this.limit, this.page, this.search);
        });
      }
    }
  }

  /**
   * Show add comment modal
   * @param commentModalContent commentModalContent to be opened
   * @param video video to be selected
   */
  showAddCommentModal(commentModalContent, video, videoId) {
    this.getVideoDetail(videoId);
    this.videoToAddComment = video;
    // Bind selected video id into form
    this.commentForm.patchValue({
      videoId: video._id,
      pathwayId: video.pathwayId,
    });
    this.commentModalRef = this.modalService.open(commentModalContent, { centered: true });
    this.commentModalRef.result.then(() => {
    }, () => {
      this.resetCommentForm();
    });
  }

  /**
   * Reset comment form
   */
  resetCommentForm() {
    this.commentForm.reset();
    this.commentForm.patchValue({
      type: this.commentTypes[0].value
    });
    this.isCommentFormSubmitted = false;
  }

  /**
   * Called when comment form gets submitted
   * @param isCommentFormValid isCommentFormValid to check whether form is valid or not
   */
  onSubmitComment(isCommentFormValid: boolean) {
    const { startTime } = this.commentForm.value;
    let startTimeInSecond;
    let isValidate: boolean = true;
    if (startTime) {
      startTimeInSecond = this.convertTimeToSecond(startTime);
      if (isNaN(startTimeInSecond)) {
        this.startTimeErrorMessage = "Start time should not be empty.";
        isValidate = false;
      }
    }
    if (startTimeInSecond >= this.totalVideoSeconds) {
      this.startTimeErrorMessage = "Start time should not be bigger that video duration.";
      isValidate = false;
    }
    this.commentForm.value.timeSlot = startTimeInSecond;

    this.commentForm.value.userId == null ? delete this.commentForm.value.userId : "";
    this.isCommentFormSubmitted = true; // To indicate that comment form has been submitted
    if (isCommentFormValid && isValidate) {
      this.pathwayService.addCommentToVideo(this.commentForm.value).subscribe((res: any) => {
        this.commentModalRef.close(); // Close comment modal
        this.resetCommentForm(); // Reset comment form
      });
    }
  }

}
