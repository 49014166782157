import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PagedList } from '../../shared';
import { Pathways } from '../models/pathways';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PathwaysService {

  youtubeAPI: string = 'https://youtube.googleapis.com/youtube/v3/videos?part=snippet&part=contentDetails'; // To store youtube api
  constructor(private http: HttpClient) { }

  /**
   * Get pathways list
   * @param limit limit to display total records
   * @param page page for which page index records
   */
  getPathwaysList(limit: number, page: number, search: string = "") {
    // Initialize parameters
    let params = new HttpParams().set("limit", limit.toString()).set("page", page.toString()).set("search", search);
    return this.http.get<PagedList<Pathways[]>>(`${environment.apiUrl}pathway/list`, { params });
  }

  /**
   * Get Subscriptions list
   * @param limit limit to display total records
   * @param page page for which page index records
   * @param search search string
   */
  getSubscriptionsList(search: string = "", filter) {
    // Initialize parameters
    let params = new HttpParams()
                  // .set("limit", pagination.limit.toString()).set("page", pagination.page.toString()) // Pagination related params
                  .set("plan", filter.plan).set("from", filter.fromDate).set("to", filter.endDate) // Filter related params
                  .set("search", search); // Search param
    return this.http.get(`${environment.apiUrl}stripe/subscriptionsList`, { params });
  }

  getSubscriptionsDetails(id){
    return this.http.get(`${environment.apiUrl}stripe/subscriptionHistory?customer=${id}`, );
  }
  
  /**
   * @returns Configurations related to subscription
   */
  getConfigurationsList() {
    let params = new HttpParams().set("hideHeader", "true");
    return this.http.get(`${environment.apiUrl}admin/getConfigurations`, { params: params });
  }

  /**
   * Sets the configuration details
   * @param data Object of configuration details
   */
  setConfigurationsList(data) {
    return this.http.post(`${environment.apiUrl}admin/setConfigurations`, data);
  }

  /**
   * Delete feedback
   * @param id id for which feedback to be deleted
   */
  deletePathway(id: string) {
    return this.http.delete(`${environment.apiUrl}pathways/delete/${id}`);
  }

  /**
   * Save pathway
   * @param pathWay pathWay to be saved
   */
  savePathWay(pathWay) {
    return this.http.post(`${environment.apiUrl}pathway/createUpdate`, pathWay);
  }

  /**
   * Get youtube video detail
   * @param videoId videoId for which video
   */
  getYoutubeVideoDetail(videoId: string) {
    let params = new HttpParams().set("hideHeader", "true");
    return this.http.get(`${this.youtubeAPI}&id=${videoId}&key=${environment.youtubeSiteKey}`, { params: params });
  }

  /**
   * Save video
   * @param video video to be saved
   */
  saveVideo(video) {
    return this.http.post(`${environment.apiUrl}pathway/video/createUpdate`, video);
  }

  /**
   * Get videos list
   * @param limit limit to display total records
   * @param page page for which page index records
   * @param search search to be filtered
   */
  getVideosList(limit: number, page: number, search: string = "") {
    // Initialize parameters
    let params = new HttpParams().set("limit", limit.toString()).set("page", page.toString()).set("search", search);
    return this.http.get<PagedList<any[]>>(`${environment.apiUrl}pathway/video/list`, { params });
  }

  /**
   * Save video hot spot
   * @param hotSpot hotSpot to be saved
   */
  saveVideoHotSpot(hotSpot: any) {
    return this.http.post(`${environment.apiUrl}dashboard/postHostSpot`, hotSpot);
  }

  /**
   * Get video hot spots
   * @param videoId videoId
   */
  getVideoHotSpots(videoId: string) {
    return this.http.get(`${environment.apiUrl}dashboard/getHostSpot`, { params: { videoId } });
  }

  /**
   * Delete video hot spot
   * @param hotSpotId
   */
  deleteVideoHotSpot(_id: string) {
    return this.http.post(`${environment.apiUrl}dashboard/deleteHostSpot`, { _id });
  }

  /**
   * Get videos list
   * @param limit limit to display total records
   * @param page page for which page index records
   * @param search search to be filtered
   */
  getVideoDetail(videoId: string) {
    // Initialize parameters
    let params = new HttpParams().set("_id", videoId);
    return this.http.get<PagedList<any[]>>(`${environment.apiUrl}pathway/video/list`, { params });
  }

  /**
   * Delete video
   * @param videoId videoId to be deleted
   */
  deleteVideo(videoId: string) {
    return this.http.delete(`${environment.apiUrl}pathway/video/delete/${videoId}`);
  }

  /**
   * Add comment to video
   * @param comment comment to be added
   */
  addCommentToVideo(comment: any) {
    return this.http.post(`${environment.apiUrl}dashboard/video/comment`, comment);
  }

  /**
   * Save video spotlight
   * @param spotLight
   */
  saveVideoSpotlight(spotLight: FormData) {
    return this.http.post(`${environment.apiUrl}pathway/spotlight/createUpdate`, spotLight);
  }

  /**
   * Get spotlights list
   * @param limit limit to display total records
   * @param page page for which page index records
   * @param search search to be filtered
   */
  getSpotlightsList(limit: number, page: number, search: string = "") {
    // Initialize parameters
    let params = new HttpParams().set("limit", limit.toString()).set("page", page.toString()).set("search", search);
    return this.http.get<PagedList<any[]>>(`${environment.apiUrl}pathway/spotlight/list`, { params });
  }

  /**
   * Delete spotlight
   * @param spotlight spotlight to be deleted
   */
  deleteSpotlight(spotlightId: string) {
    return this.http.delete(`${environment.apiUrl}pathway/video/spotlight/delete/${spotlightId}`);
  }

  /**
   * Save upskill
   * @param upskill upskill to be saved
   */
  saveUpskill(upskill) {
    return this.http.post(`${environment.apiUrl}pathway/upskill/createUpdate`, upskill);
  }

  /**
   * Get upskills list
   * @param limit limit to display total records
   * @param page page for which page index records
   * @param search search to be filtered
   */
  getUpskillsList(limit: number, page: number, search: string) {
    // Initialize parameters
    let params = new HttpParams().set("limit", limit.toString()).set("page", page.toString()).set("search", search);
    return this.http.get<PagedList<any[]>>(`${environment.apiUrl}pathway/upskill/list`, { params });
  }

  /**
   * Save question to pathway
   * @param data data to be saved
   */
  saveQuestionToPathway(data: any, isNeedToAddComment: boolean) {
    let urlToAddQuestionOrComment = isNeedToAddComment ? 'answers' : 'questions';
    return this.http.post(`${environment.apiUrl}forum/${urlToAddQuestionOrComment}/add`, data);
  }

  /**
   * Get questions by search text
   * @param searchText searchText to be filtered
   * @param pathwayId pathwayId for which pathway questions
   */
  getQuestionsBySearchText(searchText: string, pathwayId: string) {
    let forumParams: any = {
      type: 'QUESTION',
      isFlagged: false,
      search: searchText,
      pathwayId: pathwayId
    };
    return this.http.get(`${environment.apiUrl}forum/questions`, { params: forumParams })
      .pipe(map((question: any) => {
        return question.data;
      }));;
  }

  /**
   * Add popular video
   * @param videoId id of the video
   * @param isPopular popular flag
   */
  updatePopularFlag(data: any) {
    return this.http.post(`${environment.apiUrl}pathway/popular/add`, data);
  }
}
