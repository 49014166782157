import { NgModule } from '@angular/core';
import { MailInvitationComponent } from './mail-invitation/mail-invitation.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [MailInvitationComponent],
  imports: [
    SharedModule
  ]
})
export class AdminModule { }
