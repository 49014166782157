import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router
  ) { }

  /**
   * Check whether route can active or not based on user authentication
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // Get current user from local storage
    const currentUser = JSON.parse(localStorage.getItem("loggedInUser"));

    // Check if current user is not available then redirect to login page
    // Otherwise user can access page what he/she trying to access
    if (!currentUser) {
      this.router.navigate(['/login']);
      return false;
    }
    return true;
  }
}
