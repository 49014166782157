import { Component, OnInit } from '@angular/core';
import { ContactRequests } from '../models/contact-requests';
import { ContactRequestsService } from '../services/contact-requests.service';
import { PagedList } from '../../shared';
import * as moment from 'moment';

@Component({
  selector: 'tga-contact-requests',
  templateUrl: './contact-requests.component.html',
  styleUrls: ['./contact-requests.component.scss']
})
export class ContactRequestsComponent implements OnInit {

  contactRequests: ContactRequests[]; // To store contact requests list
  search: string = ''; // To store search text
  page: number = 1; // To store current page
  limit: number = 20; // To store current page size, default 20
  totalCount: number; // To store total users list count
  constructor(private contactRequestsService: ContactRequestsService) { }

  /**
   * Called when component being called
   */
  ngOnInit() {
    // Get contact requests list
    this.getContactRequests(this.search, this.limit, this.page);
  }

  /**
   * Get contact requests list
   * @param limit limit to be displayed records
   * @param page page for which page contact requests list
   */
  getContactRequests(search: string, limit: number, page: number) {

    // Get contact requests list by using contact requests service
    this.contactRequestsService.getContactRequestsList(search, limit, page).subscribe((res: PagedList<ContactRequests[]>) => {
      let data = res.data;
      for (const item of data) {
        item.createdAt = moment(item.createdAt).isValid() ? moment(item.createdAt).format('YYYY-MM-DD') : item.createdAt
      }
      this.contactRequests = data;

      this.totalCount = res.totalCount;
    });
  }

  /**
   * Called when performed search
   * @param search search to be filtered
   */
    onSearchSubmit(search: string) {
      this.page = 1;
      this.getContactRequests(search, this.limit, this.page);
    }

  /**
   * Called when page index is changed
   * @param page page to be changed
   */
  onPaginationChange(page: number) {
    this.getContactRequests(this.search, this.limit, page);
  }

}
