import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TopVoiceService {

  constructor(private http: HttpClient) { }

  /**
   * Get top voices
   * @param limit
   * @param page
   * @param search
   */
  getTopVoices(limit: number, page: number, search: string) {
    // Initialize parameters
    let params = new HttpParams().set("search", search).set("limit", limit.toString()).set("page", page.toString());
    return this.http.get(`${environment.apiUrl}topvoice/getTopVoice`, { params });
  }

  /**
   * Save top voice
   * @param topVoice topVoice to be saved
   */
  saveTopVoice(topVoice: any) {
    return this.http.post(`${environment.apiUrl}topvoice/postTopVoice`, topVoice);
  }

  /**
   * Delete top voice
   * @param topVoiceId topVoiceId to be deleted
   */
  deleteTopVoice(topVoiceId) {
    return this.http.post(`${environment.apiUrl}topvoice/deleteTopVoice`, { _id: topVoiceId });
  }
}
