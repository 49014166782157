// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: "https://stagingapi.tangramio.com/api/v1/",
  apiBaseUrl: 'https://stagingapi.tangramaio.com/',  
  // apiUrl: "https://api.tangramio.com/api/v1/",
  // apiBaseUrl: 'https://api.tangramio.com/',   
  // apiBaseUrl: 'http://ec2-3-89-89-3.compute-1.amazonaws.com:3000/', // Backend base url to access resouces
  // apiUrl: 'http://ec2-3-89-89-3.compute-1.amazonaws.com:3000/api/v1/', // Backend api url for local development
  recaptchaSiteKey: '6LeG_5gaAAAAAMm9OA34nk8vbQxY_OGGn9tZPXhr', // Google Site Key to access recaptcha
  youtubeSiteKey: 'AIzaSyD_DujENeqFwEGrFJzwKb5ehQT9F0tT4zM' // Site key to access youtube api
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
