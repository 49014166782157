import { Component, OnInit, OnDestroy } from '@angular/core';
import { PagedList } from '../../../shared';
import { Pathways } from '../../models/pathways';
import { PathwaysService } from '../../services/pathways.service';
import { LookupService } from '../../../core/services/lookup.service';
import { Preference, Role } from '../../../core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription, Observable, of } from 'rxjs';
import { ValidationService } from '../../../core/services/validation.service';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, tap, switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'tga-pathways',
  templateUrl: './pathways.component.html',
  styleUrls: ['./pathways.component.scss']
})
export class PathwaysComponent implements OnInit, OnDestroy {

  pathways: Pathways[]; // To store pathways list
  search: string = ''; // To store search text
  page: number = 1; // To store current page
  limit: number = 20; // To store current page size, default 20
  totalCount: number; // To store total users list count

  isPopularChecked: boolean = false;
  topicPereferences: any[] = []; // To store topic preferences
  admins = []; // To store admin user list

  pathwayForm: FormGroup; // To store reference of pathway form
  imageDimension = { // In KB
    width: 1050,
    height: 550
  };
  isImageDimensionValid: boolean = true; // To check whether image dimension valid or not
  allowImageMaxSize: number = 1024000; // 1MB
  pathwayFormValidationMessages = { // To store pathway form validation
    'title': {
      'required': 'Title should not be empty.',
    },
    'description': {
      'required': 'Description should not be empty.'
    },
    'topicId': {
      'required': 'Topic should be selected.'
    },
    'imageURL': {
      'invalidType': 'Please select only jpg, jpeg or png file.',
      'invalidSize': 'File should not be bigger than 1MB',
      'invalidDimension': `File maximum dimensions allowed ${this.imageDimension.width} x ${this.imageDimension.height}px`
    }
  };
  pathwayFormErrors = {
    title: '',
    description: '',
    topicId: '',
    imageURL: ''
  }; // To store errors
  isPathwayFormSubmitted: boolean = false; // To store status about pathway form submission
  pathwayFormValueChangesSubscription: Subscription;
  hidePathwayForm: boolean = true; // To hide/show pathway form
  commentForm: FormGroup; // To represent comment form
  pathwayToAddComment: any = {}; // To store pathway detail for adding comment
  isCommentFormSubmitted: boolean = false; // To check whether comment form is submitted or not
  commentModalRef: NgbModalRef;
  questionFormatter = (result: any) => result.question; //To fetch only state from object to display
  isNeedToAddComment: boolean = false;
  pathwayImageUrl: string = ""; // To store pathway image url
  currentTopicId: string = null; // To store current topic id
  curators = [] // To store curators;
  constructor(
    private pathwaysService: PathwaysService,
    private lookupService: LookupService,
    private formBuilder: FormBuilder,
    private _validation: ValidationService,
    private modalService: NgbModal,
    private toasterService: ToastrService) { }

  /**
   * Called when component being called
   */
  ngOnInit() {
    // Get pathways list
    this.getPathways(this.search, this.limit, this.page);

    // Get topic preferences
    this.getTopicPreferences();

    // Get admin list
    // this.getAdmins();

    // Get curaotrs list
    this.getCurators();

    // Build pathway form
    this.buildPathWayForm();

    this.buildCommentForm(); // Build comment form
  }

  /**
   * Called when comment checkbox gets changed
   */
  onChangePopularCheckbox(pathway: any, values: any) {
    let payload_popular = !pathway.isPopular;
    const data = {
      pathwayId: pathway.id,
      isPopular: payload_popular
    }
    this.pathwaysService.updatePopularFlag(data).subscribe((resp: any) => {
      if (!resp.success) {
        pathway.isPopular = false;
      }
      else {
        pathway.isPopular = !pathway.isPopular;
      }
      this.ngOnInit();
    });
  }

  /**
   * Get pathways list
   * @param search
   * @param limit limit to be displayed records
   * @param page page for which page pathways list
   */
  getPathways(search: string, limit: number, page: number) {

    // Get pathway list by using users service
    this.pathwaysService.getPathwaysList(limit, page, search).subscribe((res: PagedList<any[]>) => {
      this.totalCount = res.totalCount;
      let data = res.data;
      let totalPopular = res.totalPopular;
      data.forEach(eachElement => {
        if (totalPopular >= 12) {
          if (eachElement.isPopular) {
            eachElement.isDisable = false;
          }
          else {
            eachElement.isDisable = true;
          }
        }
        else if (totalPopular < 12) {
          if (totalPopular > 3) {
            eachElement.isDisable = false;
          }
          else {
            if (eachElement.isPopular) {
              eachElement.isDisable = true;  
            }
            else {
              eachElement.isDisable = false;
            }
          }
        }
      })
      this.pathways = data;
    });
  }

  /**
   * Called when performed search
   * @param search search to be filtered
   */
  onSearchSubmit(search: string) {
    this.getPathways(search, this.limit, this.page);
  }

  /**
   * Show pathway detail in form
   * @param pathway pathway to be bind
   */
  showPathwayDetailInForm(pathway) {
    this.pathwayForm.patchValue({
      pathwayId: pathway._id,
      title: pathway.title,
      description: pathway.description,
      topicId: pathway.topicId,
      adminId: pathway.adminId,
      isPopular: pathway.isPopular
    });

    this.currentTopicId = pathway.topicId;

    if (pathway.imageURL) {
      this.pathwayImageUrl = `${environment.apiBaseUrl}${pathway.imageURL}`
    }
    this.hidePathwayForm = false;
    window.scroll(0, 0); //Scroll to top
  }

  /**
   * Get topic preferences
   */
  getTopicPreferences() {
    this.lookupService.getPreferences(Preference.Topics).subscribe((data: any) => {
      this.topicPereferences = data.data;
    });
  }

  /**
   * Get admins
   */
  // getAdmins() {
  //   this.lookupService.getUsers(Role.ADMIN).subscribe((data: any) => {
  //     this.admins = data.data;
  //   });
  // }

  /**
   * Get Curators
   */
  getCurators() {
    this.lookupService.getUsers(Role.CURATOR).subscribe((data: any) => {
      this.curators = data.data;
    });
  }

  /**
   * Called when page index is changed
   * @param page page to be changed
   */
  onPaginationChange(page: number) {
    this.getPathways(this.search, this.limit, page);
  }

  /**
   * Delete pathway
   * @param id id for which pathway to be deleted
   * @param title title to be displayed in confirmation
   */
  deletePathway(id: string, title: string) {
    // Take confirmation from user and delete pathway according to confirmation
    if (confirm(`Are you sure you want to delete '${title}' pathway?`)) {
      // Delete pathway and get pathways
      this.pathwaysService.deletePathway(id).subscribe(() => {
        this.getPathways(this.search, this.limit, this.page);
      });
    }
  }

  /**
   * Build pathway form
   */
  buildPathWayForm() {
    this.pathwayForm = this.formBuilder.group({
      pathwayId: [null],
      title: ['', Validators.required],
      description: ['', Validators.required],
      isPopular: [false],
      topicId: [null, Validators.required],
      adminId: [null],
      imageURL: [null, [ValidationService.validateImageTypeExtension, ValidationService.validateImageSize(this.allowImageMaxSize)]]
    });

    this.getValidationErrors();

    // Get validation error when pathway form values get changed
    this.pathwayFormValueChangesSubscription = this.pathwayForm.valueChanges.subscribe(() => this.getValidationErrors());
  }

  /**
   * Build comment form
   */
  buildCommentForm() {
    this.commentForm = this.formBuilder.group({
      pathwayId: [null, Validators.required],
      questionId: [null],
      userId: [null],
      question: ['', [Validators.required]],
      type: ['QUESTION', Validators.required]
    });
  }

  // Get validation errors
  getValidationErrors() {
    this.pathwayFormErrors = this._validation.getValidationErrors(this.pathwayForm, this.pathwayFormValidationMessages);
  }

  /**
   * Called on file change
   * @param event event to get file
   */
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.pathwayForm.patchValue({ imageURL: file });
      let fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          const img_height = rs.currentTarget['height'];
          const img_width = rs.currentTarget['width'];
          if (img_height > this.imageDimension.height && img_width > this.imageDimension.width) {
            this.isImageDimensionValid = false;
          }
          else {
            this.isImageDimensionValid = true;
            this.pathwayImageUrl = e.target.result;
          }
        };
      };
    }
  }

  /**
   * Called when pathway form gets submittted
   * @param isPathwayFormValid isPathwayFormValid to check whether form is valid or not
   */
  onSubmit(isPathwayFormValid: boolean) {
    this.isPathwayFormSubmitted = true; // Indicate that pathway form is submitted
    if (isPathwayFormValid && this.isImageDimensionValid) {
      if (!this.checkTopicAlreadyExist()) {
        // Convert json to form data
        let formData = this._validation.getFormDataFromFormGroup(this.pathwayForm, new FormData());
        // Save pathway
        this.pathwaysService.savePathWay(formData).subscribe(() => {
          let index = this.topicPereferences.findIndex((topic) => { return topic._id == this.pathwayForm.value.topicId });
          if (index != -1) {
            this.topicPereferences[index].isPathwayAdd = true;
          }
          // Check if current topic and new selected topic are not same then make it pathway add false for current topic
          if (this.currentTopicId != this.pathwayForm.value.topicId) {
            let currentTopicIndex = this.topicPereferences.findIndex((topic) => { return topic._id == this.currentTopicId });
            if (currentTopicIndex != -1) {
              this.topicPereferences[currentTopicIndex].isPathwayAdd = false;
            }
          }
          this.pathwayForm.reset();
          this.isPathwayFormSubmitted = false;
          this.hidePathwayForm = true;
          this.pathwayImageUrl = "";
          this.isImageDimensionValid = true;
          this.currentTopicId = null;
          this.getPathways(this.search, this.limit, this.page);
        });
      }
    }

  }

  /**
   * Check topic is already exist or not
   */
  checkTopicAlreadyExist() {
    let topic = this.topicPereferences.find((topic) => { return topic._id == this.pathwayForm.value.topicId });
    if (topic && topic.isPathwayAdd && this.currentTopicId != topic._id) {
      this.toasterService.error(`The pathway with ${topic.name} topic is already exist.`);
      return true;
    }
    return false;
  }

  /**
   * Called when cancel button gets clicked
   */
  onCancelButtonClick() {
    this.pathwayForm.reset();
    this.isPathwayFormSubmitted = false;
    this.hidePathwayForm = true;
    this.pathwayImageUrl = "";
    this.isImageDimensionValid = true;
  }

  addCommentToPathway(commentModalContent, pathway) {
    this.pathwayToAddComment = pathway;
    // Bind selected video id into form
    this.commentForm.patchValue({
      pathwayId: pathway._id
    });
    this.commentModalRef = this.modalService.open(commentModalContent, { centered: true });
    this.commentModalRef.result.then(() => {
    }, () => {
      this.resetCommentForm();
    });
  }

  /**
   * Reset comment form
   */
  resetCommentForm() {
    this.commentForm.reset();
    this.commentForm.patchValue({
      type: "QUESTION"
    });
    this.isNeedToAddComment = false;
    this.commentForm.controls['questionId'].clearValidators();
    this.isCommentFormSubmitted = false;
  }

  /**
   * Called when comment form gets submitted
   * @param isCommentFormValid isCommentFormValid to check whether form is valid or not
   */
  onSubmitComment(isCommentFormValid) {
    this.isCommentFormSubmitted = true; // Indicate that form has been submitted
    if (isCommentFormValid) {
      // Extract form value
      const { questionId, question, type, pathwayId, userId } = this.commentForm.value;
      let commentFormValue = { type, pathwayId, userId };
      commentFormValue.userId == null ? delete commentFormValue.userId : "";
      if (this.isNeedToAddComment) {
        commentFormValue['questionId'] = questionId;
        commentFormValue['answer'] = question;
      }
      else {
        commentFormValue['question'] = question;
      }
      this.pathwaysService.saveQuestionToPathway(commentFormValue, this.isNeedToAddComment).subscribe(() => {
        this.commentModalRef.close(); // Close comment modal
        this.resetCommentForm(); // Reset comment form
      });
    }
  }

  //Search question
  searchQuestion = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      //tap((data) => { data.length ? this.setStateOrPostalCodeValidity('usStateID', true) : this.addressForm.controls['usStateID'].setValue(undefined); }),
      switchMap(term =>
        term.length > 2 ? this.pathwaysService.getQuestionsBySearchText(term, this.pathwayToAddComment._id) : of([])
      )
    )

  /**
   * Called when comment checkbox gets changed
   */
  onChangeCommentCheckbox() {
    this.isNeedToAddComment = !this.isNeedToAddComment;
    // Get question control and make validity based on requirement
    const question = this.commentForm.controls["questionId"];
    if (this.isNeedToAddComment) {
      question.setValidators(Validators.required);
    }
    else {
      question.clearValidators();
    }
    question.updateValueAndValidity();
  }

  /**
   * Called when question gets selected
   * @param data data to be selected
   */
  onQuestionSelect(data: any) {
    // Bind question id from the data
    this.commentForm.patchValue({
      questionId: data.item._id
    });
  }

  /**
   * Called when component being destroyed
   */
  ngOnDestroy() {
    // clean up pathway form value changes subscription
    if (this.pathwayFormValueChangesSubscription) {
      this.pathwayFormValueChangesSubscription.unsubscribe();
    }
  }
}
