import { Component, OnInit, OnDestroy } from '@angular/core';
import { SlicePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Pathways } from '../../models/pathways';
import { PathwaysService } from '../../services/pathways.service';
import * as moment from 'moment';

interface Filter {
  plan?: string; // Optional filter for plan type
  fromDate?: string; // Optional filter for start date
  endDate?: string; // Optional filter for end date
}

interface Pagination {
  page: number;
  limit: number;
  totalCount?: number; // Optional property for total count
}

@Component({
  selector: 'tga-subscriptions',
  templateUrl: './subscriptionDetails.component.html',
  styleUrls: ['./subscriptionDetails.component.scss'],
  providers: [SlicePipe] 
})
export class subscriptionDetailsComponent implements OnInit, OnDestroy {
  allSubscriptions: Pathways[] = [];
  subscriptions: Pathways[]; // To store subscriptions list
  //PAGINATION RELATED VARIABLES
  pagination: Pagination = {
    page: 1,
    limit: 10,
    totalCount: 0
  };
  subId = null;
  constructor( 
    private pathwaysService: PathwaysService,
    private slicePipe: SlicePipe,
    private activatedRoute: ActivatedRoute,

  ) { }

  /**
   * Called when component being called
   */
  ngOnInit() {
    // Get subscriptions list
    this.subId = this.activatedRoute.snapshot.params['id']
    this.getSubscriptions(this.subId);
  }

  /**
   * Get subscriptions list
   * @param search
   * @param limit limit to be displayed records
   * @param page page for which page subscriptions list
   */
  getSubscriptions(subId : any) {
    this.pathwaysService.getSubscriptionsDetails(subId).subscribe((res: any) => {
      let data = res.data;
      for (const item of data) {
        item.startDate = moment(item.startDate).isValid() ? moment(item.startDate).format('YYYY-MM-DD') : item.startDate
        item.endDate = moment(item.endDate).isValid() ? moment(item.endDate).format('YYYY-MM-DD') : item.endDate
        item.renewalDate = moment(item.renewalDate).isValid() ? moment(item.renewalDate).format('YYYY-MM-DD') : item.renewalDate
      }
      this.allSubscriptions = data;
      this.updateDisplayedSubscriptions();
    });
  }

  updateDisplayedSubscriptions() {
    const startIndex = (this.pagination.page - 1) * this.pagination.limit;
    const endIndex = startIndex + this.pagination.limit;

    this.subscriptions = this.slicePipe.transform(this.allSubscriptions, startIndex, endIndex);
    this.pagination.totalCount = this.allSubscriptions.length;
  }

  /**
   * Called when component being destroyed
   */
  ngOnDestroy() {
    // clean up pathway form value changes subscription
  }
}
