import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { NgxCaptchaModule } from 'ngx-captcha';

import { RegisterComponent, LoginComponent, ForgotPasswordComponent, ResetPasswordComponent, ChangePasswordComponent } from '../auth';

@NgModule({
  declarations: [
    RegisterComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ChangePasswordComponent
  ],
  imports: [
    SharedModule,
    NgxCaptchaModule
  ]
})
export class AuthModule { }
