import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PagedList } from '../../shared';
import { Users, UserDetail, UserCuratorDetail } from '../models/users';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  /**
   * update user role
   * @param data to be update 
   */
  updateUserRole(data: any) {
    return this.http.post(`${environment.apiUrl}users/roleUpdate`, data);
  }

  /**
   * Save user data
   * @param data to be save data 
   */
  saveUser(data: any) {
    return this.http.post<UserCuratorDetail>(`${environment.apiUrl}users/createUser`, data);
  }

  /**
   * send invitation via email to user
   * @param data send invitation to curator 
   */
  sendInvitationToUser(data: any) {
    return this.http.post<UserCuratorDetail>(`${environment.apiUrl}users/sendInvitaion`, data);
  }

  /**
   * Get users list
   * @param search search to be filtered
   * @param limit limit to display total records
   * @param page page for which page index records
   */
  getUsersList(search: string, limit: number, page: number) {
    // Initialize parameters
    let params = new HttpParams().set("search", search).set("limit", limit.toString()).set("page", page.toString());
    return this.http.get<PagedList<Users[]>>(`${environment.apiUrl}users`, { params });
  }

  /**
   * Get user detail
   * @param id id for which user detail
   */
  getUserDetail(id: string) {
    // Initialize parameters
    let params = new HttpParams().set("_id", id);
    return this.http.get<PagedList<UserDetail[]>>(`${environment.apiUrl}users`, { params });
  }
}
