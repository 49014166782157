import { Component, OnInit } from '@angular/core';
import { downloadAppEmail } from '../../models/downloadAppEmail';
import { downloadAppEmailService } from '../../services/downloadAppEmail';
import { PagedList } from '../../../shared';

@Component({
  selector: 'tga-feedbacks',
  templateUrl: './downloadAppEmail.component.html',
  styleUrls: ['./downloadAppEmail.component.scss']
})
export class downloadAppEmailComponent implements OnInit {

  downloadAppEmails: downloadAppEmail[]; // To store feedbacks list
  page: number = 1; // To store current page
  limit: number = 20; // To store current page size, default 20
  totalCount: number; // To store total users list count
  constructor(private downloadAppEmailService: downloadAppEmailService) { }

  /**
   * Called when component being called
   */
  ngOnInit() {
    // Get feedbacks list
    this.getDownloadEmails(this.limit, this.page);
  }

  /**
   * Get feedbacks list
   * @param limit limit to be displayed records
   * @param page page for which page feedbacks list
   */
  getDownloadEmails(limit: number, page: number) {

    // Get feedback list by using feedback service
    this.downloadAppEmailService.getDownloadAppEmailsList(limit, page).subscribe((res: PagedList<downloadAppEmail[]>) => {
      this.downloadAppEmails = res.data;
      this.totalCount = res.totalCount;
    });
  }

  /**
   * Called when page index is changed
   * @param page page to be changed
   */
  onPaginationChange(page: number) {
    this.getDownloadEmails(this.limit, page);
  }

}
