import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'tga-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  private isSideBarToggled: boolean = false; // To toggle sidebar
  loggedInUser: string = JSON.parse(localStorage.getItem("loggedInUser"));

  constructor(
    private renderer: Renderer2,
    private router: Router) { }

  /**
   * Called when component being called
   */
  ngOnInit() {
  }

  /**
   * Toggle sidebar
   */
  toggleSideBar() {
    this.isSideBarToggled = !this.isSideBarToggled;

    // Check if side bar is toggled then add class to open sidebar otherwise remove class to hide sidebar
    if (this.isSideBarToggled)
      this.renderer.addClass(document.body, 'sb-sidenav-toggled');
    else
      this.renderer.removeClass(document.body, 'sb-sidenav-toggled');
  }

  /**
   * Do logout
   */
  doLogout() {
    // Make logged in user value null in local storage
    localStorage.setItem("loggedInUser", null);
    // Redirect to login
    this.router.navigate(['/login']);
  }

}
