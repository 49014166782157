import { Injectable } from '@angular/core';
import { PagedList } from '../../shared';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Tag } from '../models/tag';

@Injectable({
  providedIn: 'root'
})
export class LookupService {

  constructor(private http: HttpClient) { }

  /**
   * Get preferences list
   * @param preferenceName preferenceName which preferences
   */
  getPreferences(preferenceName: string) {
    return this.http.get(`${environment.apiUrl}preferences/${preferenceName}`);
  }

  /**
   * Get users
   * @param role role which users
   */
  getUsers(role: string) {
    // Initialize parameters
    let params = new HttpParams().set("role", role);
    return this.http.get(`${environment.apiUrl}users`, { params });
  }

  /**
   * Get tags
   */
  getTags() {
    return this.http.get<PagedList<Tag[]>>(`${environment.apiUrl}tags`);
  }
}
