import { NgModule } from '@angular/core';
import { UsersComponent } from '../users';
import { UserDetailComponent } from './components/user-detail/user-detail.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    UsersComponent,
    UserDetailComponent,    
  ],
  imports: [
    SharedModule,
  ]
})
export class UsersModule { }
