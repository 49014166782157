import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient) { }

  /**
   * Send mail invitation to admin registration
   * @param data data
   */
  sendMailInvitationToAdminRegistration(data: string) {
    return this.http.post(`${environment.apiUrl}admin/sendRegisterEmailAdmin`, data);
  }
}
