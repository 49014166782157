import { NgModule } from '@angular/core';
import { FlaggedCommentsComponent } from './components/flagged-comments/flagged-comments.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [FlaggedCommentsComponent],
  imports: [
    SharedModule
  ]
})
export class FlaggedCommentsModule { }
