import { Injectable } from '@angular/core';
import { dashboard } from '../models/dashboard';
import { PagedList } from '../../shared';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class dashboardEmailService {

  constructor(private http: HttpClient) { }

  /**
   * Get feedbacks list
   */
  // Initialize parameters
  getDashboardCounts(startDate, endDate) {
    return this.http.get<PagedList<dashboard[]>>(`${environment.apiUrl}dashboard/getDashboardCount?startDate=${startDate}&endDate=${endDate}`);
  }
}
