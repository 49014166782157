import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from '../../../core/services/validation.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'tga-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup; // To store form reference
  loginFormvalidationMessages = { // To store login form validation
    'email': {
      'required': 'Email should not be empty.',
      'pattern': 'Email must contain @, domain name, .(dot)'
    },
    'password': {
      'required': 'Password should not be empty.'
    }
  };
  loginFormErrors = {
    email: '',
    password: ''
  }; // To store errors
  isLoginFormSubmitted: boolean = false; // To store status about login form submission
  loginFormValueChangesSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private _validation: ValidationService,
    private router: Router,
    private authService: AuthService) { }

  /**
   * Called when comopnent being initialized
   */
  ngOnInit() {
    this.buildLoginForm();
  }

  /** Build login form */
  private buildLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(this._validation.regex.email)]],
      password: ['', Validators.required],
    });

    this.getValidationErrors();

    // Get validation error when login form values get changed
    this.loginFormValueChangesSubscription = this.loginForm.valueChanges.subscribe(() => this.getValidationErrors());
  }

  // Get validation errors
  getValidationErrors() {
    this.loginFormErrors = this._validation.getValidationErrors(this.loginForm, this.loginFormvalidationMessages);
  }

  /**
   * Called when login form is submitted
   * @param isLoginFormValid isLoginFormValid to check whether login form is valid or not
   */
  onSubmit(isLoginFormValid) {
    this.isLoginFormSubmitted = true; // Indicate that form is submitted

    if (isLoginFormValid) {

      this.authService.doLogin(this.loginForm.value).subscribe((data: any) => {
        if (data.success) {
          // Store auth token in local storage
          localStorage.setItem("auth-token", data.data.token);
          // Store email in local storage
          localStorage.setItem("loggedInUser", JSON.stringify(this.loginForm.value.email));
          // Redirect to user list
          this.router.navigate(['/user/list']);
        }
      });
    }

  }

  /**
   * Called when component being destroyed
   */
  ngOnDestroy() {
    if (this.loginFormValueChangesSubscription) {
      // clean up login form value changes subscription
      this.loginFormValueChangesSubscription.unsubscribe();
    }
  }
}
