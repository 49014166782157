import { Component } from '@angular/core';

@Component({
  selector: 'tga-user-layout',
  templateUrl: './user-layout.component.html',
  styleUrls: ['./user-layout.component.scss']
})
export class UserLayoutComponent {

}
