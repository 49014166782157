import { Component, OnInit } from '@angular/core';
import { Users } from '../../models/users';
import { UserService } from '../../services/user.service';
import { PagedList } from '../../../shared';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from 'src/app/core/services/validation.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'tga-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  users: Users[]; // To store users list
  search: string = ''; // To store search text
  page: number = 1; // To store current page
  limit: number = 20; // To store current page size, default 20
  totalCount: number; // To store total users list count
  hideCuratorForm: boolean = true; // To hide show user form
  curatorForm: FormGroup; // To represent user form
  userFormValueChangesSubscription: Subscription;
  userFormErrors = {
    firstName: '',
    lastName: '',
    email: '',
    screenName: '',
    role: ''
  }; // To store errors
  userRoles = ['USER', 'CURATOR']; // To store user role
  userFormValidationMessages = { // To store form validation
    'firstName': {
      'required': 'FirstName should not be empty.',
    },
    'lastName': {
      'required': 'LastName should not be empty.',
    },
    'email': {
      'required': 'Email should not be empty.',
      'pattern': 'Email must contain @, domain name, .(dot)'
    },
    'screenName': {
      'required': 'ScreenName should not be empty.',
    },
    'role': {
      'required': 'Role should not be empty.',
      'roleUpdate': 'Role should be curator.'
    },
  };
  iscuratorFormSubmitted: boolean = false;
  profileImageUrl: string = '';
  showEditUserForm: boolean = false;
  showRoleError: boolean = false;
  constructor(
    private _validation: ValidationService,
    private formBuilder: FormBuilder,
    private userService: UserService) { }

  /**
   * Called when component being called
   */
  ngOnInit() {
    // build user form
    this.buildUserForm();
    // Get users list
    this.getUsers(this.search, this.limit, this.page);
  }

  openCuratorForm() {
    this.hideCuratorForm = false;
    this.curatorForm.patchValue({
      role: this.userRoles[1]
    });
  }

  // Build user form
  buildUserForm() {
    this.curatorForm = this.formBuilder.group({
      _id: [null],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this._validation.regex.email)]],
      screenName: ['', Validators.required],
      image: [],
      role: [this.userRoles[1], Validators.required],
      createdBy: [null],
      isVerified: [null]
    });

    this.getValidationErrors();

    // Get validation error when video form values get changed
    this.userFormValueChangesSubscription = this.curatorForm.valueChanges.subscribe(() => this.getValidationErrors());
  }

  // Get validation errors
  getValidationErrors() {
    this.userFormErrors = this._validation.getValidationErrors(this.curatorForm, this.userFormValidationMessages);
  }

  /**
   * Called when cancel button gets clicked
   */
  onCancelButtonClick() {
    this.showEditUserForm = false;
    this.iscuratorFormSubmitted = false;
    this.resetUserForm();
  }

  /**
   * Reset user form
   */
  resetUserForm() {
    this.curatorForm.reset();
    this.hideCuratorForm = true;
    this.profileImageUrl = "";
    this.showRoleError = false;
  }

  /**
   * Called on file change
   * @param event event to get file
   */
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.curatorForm.patchValue({ image: file });
      //Spotlight image url
      let fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (e: any) => {
        this.profileImageUrl = e.target.result;
      };
    }
  }

  /**
   * send invitation to user
   */
  sendInvitation() {

    this.userService.sendInvitationToUser({
      "_id": this.curatorForm.value._id,
      "email": this.curatorForm.value.email
    }).subscribe(() => {
      this.hideCuratorForm = true;
    });
  }

  /**
   * Called when user form gets submitted
   * @param isUserFormValid isUserFormValid to check whether user form is valid or not
   */
  onSubmit(isUserFormValid: boolean) {
    this.iscuratorFormSubmitted = true; // Indicate that form is submitted
    this.showRoleError = false;
    if (isUserFormValid) {
      // compare role is curator or not
      if (this.curatorForm.value.role == this.userRoles[1]) {
        if (this.showEditUserForm) {
          this.userService.updateUserRole({
            _id: this.curatorForm.value._id,
            role: this.curatorForm.value.role
          }).subscribe(() => {
            this.hideCuratorForm = true;
          });
        } else {
          let formData = this._validation.getFormDataFromFormGroup(this.curatorForm, new FormData());
          this.userService.saveUser(formData).subscribe(() => {
            this.iscuratorFormSubmitted = false;
            this.hideCuratorForm = true;
          });
        }
      } else {
        // call when selected role is user so display error message to select curator
        this.showRoleError = true;
      }
    }
  }

  /**
   * get user detail to edit user role
   * @param userId update user role to curator only
   */
  getUserDetail(user) {
    this.showEditUserForm = true; // display edit form 
    this.showRoleError = false;
    this.curatorForm.patchValue({
      _id: user._id,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      screenName: user.screenName,
      role: user.role,
      createdBy: user.createdBy,
      isVerified: user.isVerified
    });
    if (user.profilePic) {
      this.profileImageUrl = `${environment.apiBaseUrl}${user.profilePic}`
    }
    this.hideCuratorForm = false;
    window.scroll(0, 0); //Scroll to top
  }

  /**
   * Get users list
   * @param search search to be filtered
   * @param limit limit to be displayed records
   * @param page page for which page users list
   */
  getUsers(search: string, limit: number, page: number) {

    // Get users list by using users service
    this.userService.getUsersList(search, limit, page).subscribe((res: PagedList<Users[]>) => {
      this.users = res.data;
      this.totalCount = res.totalCount;
    });
  }

  /**
   * Called when performed search
   * @param search search to be filtered
   */
  onSearchSubmit(search: string) {
    this.getUsers(search, this.limit, this.page);
  }

  /**
   * Called when page index is changed
   * @param page page to be changed
   */
  onPaginationChange(page: number) {
    this.getUsers(this.search, this.limit, page);
  }

}
