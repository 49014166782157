import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent, LoginComponent, ForgotPasswordComponent, ResetPasswordComponent, ChangePasswordComponent } from './auth';
import { UsersComponent, UserDetailComponent } from './users';
import { PageNotFoundComponent, AuthGuard, UnauthorizeComponent, GuestLayoutComponent, UserLayoutComponent, GuestGuard } from './core';
import { PathwaysComponent, AddPathwayComponent, VideosComponent, SpotlightsComponent, UpskillsComponent, SubscriptionsComponent, ConfigurationsComponent, VideoHotSpotComponent,subscriptionDetailsComponent } from './pathways';
import { FlaggedCommentsComponent } from './flagged-comments';
import { FeedbacksComponent } from './feedback';
import { ContactRequestsComponent } from './contact-requests'
import { MailInvitationComponent } from './admin';
import { TopVoicesComponent } from './top-voice';
import { downloadAppEmailComponent } from './downloadAppEmail'
import { dashboardComponent } from './dashboard'

/** All Routes */
const routes: Routes = [
  {
    path: '', component: GuestLayoutComponent,
    canActivate: [GuestGuard],
    children: [
      { path: 'register/:email/:token', component: RegisterComponent, data: { title: 'Register' } },
      { path: 'login', component: LoginComponent, data: { title: 'Login' } },
      { path: 'forgot-password', component: ForgotPasswordComponent, data: { title: 'Forgot Password' } },
      { path: 'reset-password/:token', component: ResetPasswordComponent, data: { title: 'Reset Password' } },
      { path: '', redirectTo: '/login', pathMatch: 'full' },
    ]
  },
  {
    path: '', component: UserLayoutComponent,
    children: [
      { path: 'change-password', component: ChangePasswordComponent, data: { title: 'Change Password' } },
      {
        path: 'user',
        canActivate: [AuthGuard],
        children: [
          { path: 'list', component: UsersComponent, data: { title: 'User List' } },
          { path: 'detail/:id', component: UserDetailComponent, data: { title: 'User Detail' } },
          { path: '', redirectTo: 'list', pathMatch: 'full' }
        ]
      },
      {
        path: 'pathway',
        canActivate: [AuthGuard],
        children: [
          { path: 'list', component: PathwaysComponent, data: { title: 'Pathway List' } },
          { path: 'add', component: AddPathwayComponent, data: { title: 'Add Pathway' } },
          { path: 'videos', component: VideosComponent, data: { title: 'Video List' } },
          { path: 'videos/:videoId/hotspots', component: VideoHotSpotComponent, data: { title: 'Video Hot Spots' } },
          { path: 'spotlights', component: SpotlightsComponent, data: { title: 'Spotlight List' } },
          { path: 'upskills', component: UpskillsComponent, data: { title: 'Upskill List' } },
          { path: 'subscriptions', component: SubscriptionsComponent, data: { title: 'Subscriptions' } },
          { path: 'subscriptionDetails/:id', component: subscriptionDetailsComponent, data: { title: 'subscriptionDetails' } },
          { path: 'configurations', component: ConfigurationsComponent, data: { title: 'Configurations' } },          
          { path: ':pathwayId/flagged-comments', component: FlaggedCommentsComponent, data: { title: 'Flagged Comment List' } },
          { path: '', redirectTo: 'list', pathMatch: 'full' }
        ]
      },
      {
        path: 'flagged-comment',
        canActivate: [AuthGuard],
        children: [
          { path: 'list', component: FlaggedCommentsComponent, data: { title: 'Flagged Comment List' } },
          { path: '', redirectTo: 'list', pathMatch: 'full' }
        ]
      },
      {
        path: 'top-voice',
        canActivate: [AuthGuard],
        children: [
          { path: 'list', component: TopVoicesComponent, data: { title: 'Top Voice List' } },
          { path: '', redirectTo: 'list', pathMatch: 'full' }
        ]
      },
      {
        path: 'feedback',
        canActivate: [AuthGuard],
        children: [
          { path: 'list', component: FeedbacksComponent, data: { title: 'Feedback List' } }
        ]
      },
      {
        path: 'contact-requests',
        canActivate: [AuthGuard],
        children: [
          { path: 'list', component: ContactRequestsComponent, data: { title: 'Contact Requests' } }
        ]
      },
      {
        path: 'downloadApp',
        canActivate: [AuthGuard],
        children: [
          { path: 'list', component: downloadAppEmailComponent, data: { title: 'DownloadAppEmail List' } }
        ]
      },
      {
        path: 'admin',
        canActivate: [AuthGuard],
        children: [
          { path: 'mail-invitation', component: MailInvitationComponent, data: { title: 'Mail Invitation' } }
        ]
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        children: [
          { path: 'list', component: dashboardComponent, data: { title: 'DownloadAppEmail List' } }
        ]
      },
    ]
  },
  { path: 'unauthorized', component: UnauthorizeComponent, data: { title: 'Unauthorized' } },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', component: PageNotFoundComponent, data: { title: 'Page not found' } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
