import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PathwaysService } from '../../services/pathways.service';
import { ValidationService } from '../../../core/services/validation.service';
import { PagedList } from '../../../shared';
import { Pathways } from '../../models/pathways';

@Component({
  selector: 'tga-upskills',
  templateUrl: './upskills.component.html',
  styleUrls: ['./upskills.component.scss']
})
export class UpskillsComponent implements OnInit {

  upskillForm: FormGroup; // To represent upskill form
  isUpskillFormSubmitted: boolean = false; // To store status of upskill form submission
  upskillTypes: any[] = ["BOOK", "PODCAST", "WEBSITE"]; // To store upskill types
  upskillFormValidationMessages = { // To store upskill form validation
    'pathwayId': {
      'required': 'Pathway should be selected.'
    },
    'type': {
      'required': 'Skill Type Video should be selected.'
    },
    'title': {
      'required': 'Title should not be empty.'
    },
    'URL': {
      'required': 'URL should not be empty.'
    }
  };
  upskillFormErrors = {
    pathwayId:'',
    type:'',
    title:'',
    URL:''
  }; // To store errors
  upskillFormValueChangesSubscription: Subscription;
  hideUpskillForm: boolean = true; // To hide show upskill form
  pathways: any[] = []; // To store pathways list
  upskills: any[]; // To store upskills list
  page: number = 1; // To store current page
  limit: number = 20; // To store current page size, default 20
  totalCount: number; // To store total upskill list count
  search: string = ''; // To store search text
  constructor(
    private formBuilder: FormBuilder,
    private pathwayService: PathwaysService,
    private _validation: ValidationService
  ) { }

  /**
   * Called when component being called
   */
  ngOnInit() {
    // Build upskill form
    this.buildUpskillForm();

    // Get pathways list
    this.getPathways();

    // Get upskills
    this.getUpskills(this.limit, this.page);
  }

  /**
   * Get upskills list
   * @param limit limit to be displayed records
   * @param page page for which page users list
   */
  getUpskills(limit: number, page: number, search: string = "") {
    this.pathwayService.getUpskillsList(limit, page, search).subscribe((data: PagedList<any[]>) => {
      this.upskills = data.data;
      this.totalCount = data.totalCount;
    });
  }

  /**
   * Called when performed search
   * @param search search to be filtered
   */
  onSearchSubmit(search: string) {
    this.getUpskills(this.limit, this.page, search);
  }

  /**
   * Show upskill into form
   * @param upskill upskill to be updated
   */
  showUpskillToForm(upskill) {
    // Bind detail into form
    this.upskillForm.patchValue({
      _id: upskill._id,
      pathwayId: upskill.pathwayId,
      type: upskill.type,
      title: upskill.title,
      URL: upskill.URL
    });
    // Show add edit form
    this.hideUpskillForm = false;
    // Scroll to top
    window.scroll(0, 0);
  }

  /**
   * Called when page index is changed
   * @param page page to be changed
   */
  onPaginationChange(page: number) {
    this.getUpskills(this.limit, page, this.search);
  }

  // Build upskill form
  buildUpskillForm() {
    this.upskillForm = this.formBuilder.group({
      _id: [null],
      pathwayId: [null, Validators.required],
      type: [null, Validators.required],
      title: ['', Validators.required],
      URL: ['', Validators.required],
    });

    this.getValidationErrors();

    // Get validation error when upskill form values get changed
    this.upskillFormValueChangesSubscription = this.upskillForm.valueChanges.subscribe(() => this.getValidationErrors());
  }

  // Get validation errors
  getValidationErrors() {
    this.upskillFormErrors = this._validation.getValidationErrors(this.upskillForm, this.upskillFormValidationMessages);
  }

  /**
   * Get pathways list
   */
  getPathways() {
    // Get pathway list by using pathway service
    this.pathwayService.getPathwaysList(20, 1).subscribe((res: PagedList<Pathways[]>) => {
      this.pathways = res.data;
    });
  }

  /**
   * Called when cancel button gets clicked
   */
  onCancelButtonClick() {
    this.resetUpskillForm();
  }

  /**
   * Reset upskill form
   */
  resetUpskillForm() {
    this.upskillForm.reset();
    this.isUpskillFormSubmitted = false;
    this.hideUpskillForm = true;
  }

  /**
   * Called when form gets submitted
   * @param isFormValid isFormSubmitted to check whether form is valid or not
   */
  onSubmit(isFormValid) {
    this.isUpskillFormSubmitted = true; // Indicate that upskill form is submitted
    if (isFormValid) {
      this.pathwayService.saveUpskill(this.upskillForm.value).subscribe(() => {
        this.resetUpskillForm();
        this.getUpskills(this.limit, this.page, this.search);
      });
    }

  }

}
