import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ValidationService } from '../../../core/services/validation.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'tga-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: FormGroup; // To store forgot password form reference
  forgotPasswordFormValidationMessages = { // To store forgot password form validation
    'email': {
      'required': 'Email should not be empty.',
      'pattern': 'Email must contain @, domain name, .(dot)'
    }
  };
  forgotPasswordFormErrors = { email: '' }; // To store errors
  isForgotPasswordFormSubmitted: boolean = false; // To store status about form submission
  forgotPasswordFormValueChangesSubscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private _validation: ValidationService,
    private authService: AuthService,
    private router: Router) { }

  /**
   * Called when comopnent being initialized
   */
  ngOnInit() {
    this.buildForogotPasswordForm();
  }

  /** Build forgot password form */
  private buildForogotPasswordForm() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(this._validation.regex.email)]]
    });

    this.getValidationErrors();

    // Get validation error when forgot password form values are changed
    this.forgotPasswordFormValueChangesSubscription = this.forgotPasswordForm.valueChanges.subscribe(() => this.getValidationErrors());
  }

  // Get validation errors
  getValidationErrors() {
    this.forgotPasswordFormErrors = this._validation.getValidationErrors(this.forgotPasswordForm, this.forgotPasswordFormValidationMessages);
  }

  /**
   * Called when forgot password form is submitted
   * @param isForgotPasswordFormValid isForgotPasswordFormValid to check whether password form is valid or not
   */
  onSubmit(isForgotPasswordFormValid) {
    this.isForgotPasswordFormSubmitted = true; // Indicate that form is submitted

    // If forgot password form is valid then call backend api to send email and reset password
    if (isForgotPasswordFormValid) {
      this.authService.forgotPassword(this.forgotPasswordForm.value.email).subscribe((res) => {
        this.router.navigate(['login']);
      });
    }

  }

  /**
   * Called when component being destroyed
   */
  ngOnDestroy() {
    // clean up forgot password form value changes subscription
    if (this.forgotPasswordFormValueChangesSubscription) {
      this.forgotPasswordFormValueChangesSubscription.unsubscribe();
    }
  }
}
