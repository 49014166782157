import { NgModule } from '@angular/core';
import { FeedbacksComponent } from './components/feedbacks/feedbacks.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [FeedbacksComponent],
  imports: [
    SharedModule
  ]
})
export class FeedbackModule { }
