import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { PathwaysComponent } from '../pathways';
import { AddPathwayComponent } from './components/add-pathway/add-pathway.component';
import { VideosComponent } from './components/videos/videos.component';
import { SpotlightsComponent } from './components/spotlights/spotlights.component';
import { UpskillsComponent } from './components/upskills/upskills.component';
import { SubscriptionsComponent } from './components/subscriptions/subscriptions.component';
import { subscriptionDetailsComponent } from './components/subscriptionDetails/subscriptionDetails.component';

import { ConfigurationsComponent } from './components/configurations/configurations.component'
import { VideoHotSpotComponent } from './components/video-hot-spot/video-hot-spot.component';

@NgModule({
  declarations: [PathwaysComponent, AddPathwayComponent, VideosComponent, SpotlightsComponent, UpskillsComponent, SubscriptionsComponent, ConfigurationsComponent, VideoHotSpotComponent,subscriptionDetailsComponent],
  imports: [
    SharedModule
  ]
})
export class PathwaysModule { }
