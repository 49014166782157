import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ValidationService } from '../../../core/services/validation.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { environment } from '../../../../environments/environment';
import { ReCaptchaV3Service } from 'ngx-captcha';

@Component({
  selector: 'tga-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit, OnDestroy {

  registerForm: FormGroup; // To store form reference
  registerFormvalidationMessages = { // To store register form validation
    'firstName': {
      'required': 'First name should not be empty.'
    },
    'lastName': {
      'required': 'Last name should not be empty.'
    },
    'email': {
      'required': 'Email should not be empty.',
      'pattern': 'Email must contain @, domain name, .(dot)'
    },
    'password': {
      'required': 'Password should not be empty.',
      'pattern': ''
    },
    'confirmPassword': {
      'required': 'Confirm Password should not be empty.',
      'compare': 'Password & confirm password mismatches'
    }
  };
  registerFormErrors = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: ''
  }; // To store errors
  isRegisterFormSubmitted: boolean = false; // To store status about register form submission
  registerFormValueChangesSubscription: Subscription;
  passwordPatternValidationMessages: string[] = []; // To store password validation messages
  email: string = ""; // To store email address from the query param
  token: string = ""; // To store token from the query param
  firstName: string = ""; // To store first name from the query param
  lastName: string = ""; // To store last name from the query param
  siteKey: string = "";
  queryParamSubscription: Subscription;
  constructor(
    private formBuilder: FormBuilder,
    private _validation: ValidationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private reCaptchaV3Service: ReCaptchaV3Service) {
    // Bind email and token from params
    this.email = this.activatedRoute.snapshot.params['email'];
    this.token = this.activatedRoute.snapshot.params['token'];
    this.queryParamSubscription = this.activatedRoute.queryParams.subscribe((param) => {
      // Set firstname if available
      if (param.firstName) {
        this.firstName = param.firstName;
      }
      // Set lastname if available
      if (param.lastName) {
        this.lastName = param.lastName;
      }
    });
  }

  /**
   * Called when comopnent being initialized
   */
  ngOnInit() {
    this.buildRegisterForm();
    this.siteKey = environment.recaptchaSiteKey
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  /** Build register form */
  private buildRegisterForm() {
    this.registerForm = this.formBuilder.group({
      firstName: [this.firstName, Validators.required],
      lastName: [this.lastName, Validators.required],
      email: [{ value: this.email, disabled: true }],
      password: ['', [Validators.required, Validators.pattern(this._validation.regex.password)]],
      confirmPassword: ['', Validators.required],
      token: [this.token]
    },
      { validators: this._validation.comparePassword("password", "confirmPassword") }
    );

    this.getValidationErrors();

    // Get validation error when register form values get changed
    this.registerFormValueChangesSubscription = this.registerForm.valueChanges.subscribe((register) => {
      this.passwordPatternValidationMessages = this._validation.getPasswordPatternValidationMessages(register.password);
      this.getValidationErrors()
    });
  }

  // Get validation errors
  getValidationErrors() {
    this.registerFormErrors = this._validation.getValidationErrors(this.registerForm, this.registerFormvalidationMessages);
  }

  /**
   * Called when register form gets submitted
   * @param isRegisterFormValid isRegisterFormValid to check whether register form is valid or not
   */
  onSubmit(isRegisterFormValid) {
    this.isRegisterFormSubmitted = true; // Indicate that form is submitted

    if (isRegisterFormValid) {
      // Call execute method for getting recaptch v3 token
      this.reCaptchaV3Service.execute(this.siteKey, 'submit', (token) => {
        // Verify recaptcha V3 token
        this.authService.verifyRecaptchaV3Token(token).subscribe((data: any) => {
          // Check if token is valid then register the admin and redirect to login page
          if (data.success) {
            let data = this.registerForm.value;
            data.email = this.registerForm.get("email").value;

            this.authService.registerAdmin(data).subscribe(() => {
              // Redirect to login
              this.router.navigate(['/login']);
            });
          }
        });
      });
    }

  }

  /**
   * Called when component being destroyed
   */
  ngOnDestroy() {
    if (this.registerFormValueChangesSubscription) {
      // clean up register form value changes subscription
      this.registerFormValueChangesSubscription.unsubscribe();
    }

    if (this.queryParamSubscription) {
      this.queryParamSubscription.unsubscribe();
    }
  }

}
