import { Component, OnInit, OnDestroy } from "@angular/core";
import { PathwaysService } from "../../services/pathways.service";

@Component({
  selector: "tga-configurations",
  templateUrl: "./configurations.component.html",
  styleUrls: ["./configurations.component.scss"],
})
export class ConfigurationsComponent implements OnInit, OnDestroy {
  maxBasicTangram: number = 0;
  maxBasicMedia: number = 0;
  maxPremiumTangram: number = 0;
  maxPremiumMedia: number = 0;
  supportEmail: string;
  constructor(private pathwaysService: PathwaysService) {}

  /**
   * Called when component being called
   */
  ngOnInit() {
    // Get subscriptions list
    this.getConfigurations();
  }

  /**
   * Get configurations list
   */
  getConfigurations() {
    // Get pathway list by using users service
    this.pathwaysService.getConfigurationsList().subscribe((res: any) => {
      if (res.data.length) {
        let data = res.data[0];
        this.maxBasicTangram = data.maxBasicTangram;
        this.maxBasicMedia = data.maxBasicMedia;
        this.maxPremiumTangram = data.maxPremiumTangram;
        this.maxPremiumMedia = data.maxPremiumMedia;
        this.supportEmail = data.supportEmail;
      }
    });
  }

  onSubmit() {
    let data = {
      maxBasicTangram: this.maxBasicTangram,
      maxBasicMedia: this.maxBasicMedia,
      maxPremiumTangram: this.maxPremiumTangram,
      maxPremiumMedia: this.maxPremiumMedia,
      supportEmail: this.supportEmail
    }
    this.pathwaysService.setConfigurationsList(data).subscribe((res: any) => {});
  }

  /**
   * Called when component being destroyed
   */
  ngOnDestroy() {
    // clean up pathway form value changes subscription
  }
}
