export class Users {
  _id: string;
  name: string;
  email: string;
  isVerified: boolean;
}

export class UserDetail {
  _id: string = null;
  name: string = "";
  email: string = "";
  firstName: string = "";
  lastName: string = "";
}

export class UserCuratorDetail {
  _id: string = null;
  name: string = "";
  email: string = "";
  firstName: string = "";
  lastName: string = "";
  screenName: string = "";
  role: string = "";
  image: any;
}