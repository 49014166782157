import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PathwaysService } from '../../services/pathways.service';
import { ValidationService } from '../../../core/services/validation.service';
import { PagedList } from '../../../shared';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'tga-spotlights',
  templateUrl: './spotlights.component.html',
  styleUrls: ['./spotlights.component.scss']
})
export class SpotlightsComponent implements OnInit, OnDestroy {

  spotlightForm: FormGroup; // To represent spotlight form
  isSpotlightFormSubmitted: boolean = false; // To store status of spotlight form submission
  spotlightFormValidationMessages = { // To store spotlight form validation
    'videoId': {
      'required': 'Youtube Video should not be empty.'
    },
    'title': {
      'required': 'Title should not be empty.'
    },
    'description': {
      'required': 'Description should not be empty.'
    },
    'URL': {
      'required': 'URL should not be empty.'
    }
  };
  spotlightFormErrors = {
    videoId: '',
    title: '',
    description: '',
    URL: ''
  }; // To store errors
  spotlightImageUrl: string = ""; // To store spotlight image url
  spotlightFormValueChangesSubscription: Subscription;
  hideSpotlightForm: boolean = true; // To hide show spotlight form
  videos: any[] = []; // To store videos list
  spotlights: any[]; // To store spotlights list
  page: number = 1; // To store current page
  limit: number = 20; // To store current page size, default 20
  totalCount: number; // To store total users list count
  search: string = ''; // To store search text
  constructor(
    private formBuilder: FormBuilder,
    private pathwayService: PathwaysService,
    private _validation: ValidationService
  ) { }

  /**
   * Called when component being called
   */
  ngOnInit() {
    // Build spotlight form
    this.buildSpotlightForm();

    // Get videos list
    this.getVideosList();

    // Get spotlights list
    this.getSpotlightsList(this.limit, this.page);
  }

  // Build spotlight form
  buildSpotlightForm() {
    this.spotlightForm = this.formBuilder.group({
      _id: [null],
      videoId: [null, Validators.required],
      title: ['', Validators.required],
      description: ['', Validators.required],
      URL: ['', Validators.required],
      image: []
    });

    this.getValidationErrors();

    // Get validation error when spotlight form values get changed
    this.spotlightFormValueChangesSubscription = this.spotlightForm.valueChanges.subscribe(() => this.getValidationErrors());
  }

  // Get validation errors
  getValidationErrors() {
    this.spotlightFormErrors = this._validation.getValidationErrors(this.spotlightForm, this.spotlightFormValidationMessages);
  }

  /**
   * Get spotlights list
   * @param limit limit to be displayed records
   * @param page page for which page users list
   */
  getSpotlightsList(limit: number, page: number, search: string = "") {
    this.pathwayService.getSpotlightsList(limit, page, search).subscribe((data: PagedList<any[]>) => {
      this.spotlights = data.data;
      this.totalCount = data.totalCount;
    });
  }

  /**
   * Called when performed search
   * @param search search to be filtered
   */
  onSearchSubmit(search: string) {
    this.getSpotlightsList(this.limit, this.page, search);
  }

  /**
   * Show spotlight detail into form
   * @param spotlight spotlight to be updated
   */
  showSpotlightDetailToForm(spotlight) {
    // Bind spotlight detail into form
    this.spotlightForm.patchValue({
      _id: spotlight._id,
      videoId: spotlight.videoId,
      title: spotlight.title,
      description: spotlight.description,
      URL: spotlight.URL
    });
    // Show image url
    if (spotlight.imageURL) {
      this.spotlightImageUrl = `${environment.apiBaseUrl}${spotlight.imageURL}`
    }
    // Show add edit form
    this.hideSpotlightForm = false;
    // Scroll to top
    window.scroll(0, 0);
  }

  /**
   * Called when page index is changed
   * @param page page to be changed
   */
  onPaginationChange(page: number) {
    this.getSpotlightsList(this.limit, page, this.search);
  }

  /**
   * Delete spotlight
   * @param spotlight spotlight to be deleted
   */
  deleteSpotlight(spotlight) {
    // Get confirmation from user about deletion of video
    if (confirm(`Are you sure you want to delete '${spotlight.title}' spotlight?`)) {
      // Delete spotlight
      this.pathwayService.deleteSpotlight(spotlight._id).subscribe(() => {
        // Get spotlights list after deleteing spotlight video
        this.getSpotlightsList(this.limit, this.page);
      });
    }
  }

  /**
   * Get videos list
   */
  getVideosList() {
    this.pathwayService.getVideosList(20, 1).subscribe((data: PagedList<any[]>) => {
      this.videos = data.data;
    });
  }

  /**
   * Called when cancel button gets clicked
   */
  onCancelButtonClick() {
    this.resetSpotlightForm();
  }

  /**
   * Reset spotlight form
   */
  resetSpotlightForm() {
    this.spotlightForm.reset();
    this.isSpotlightFormSubmitted = false;
    this.hideSpotlightForm = true;
    this.spotlightImageUrl = "";
  }

  /**
   * Called on file change
   * @param event event to get file
   */
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.spotlightForm.patchValue({ image: file });
      //Spotlight image url
      let fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (e: any) => {
        this.spotlightImageUrl = e.target.result;
      };
    }
  }

  /**
   * Called when spotlight form gets submitted 
   * @param isSpotlightFormSubmitted isSpotlightFormSubmitted to check whether form is submitted or not
   */
  onSubmit(isSpotlightFormSubmitted) {
    this.isSpotlightFormSubmitted = true; // Indicate that form is submitted
    if (isSpotlightFormSubmitted) {
      // Convert json to form data
      let formData = this._validation.getFormDataFromFormGroup(this.spotlightForm, new FormData());
      // Save video spotlight
      this.pathwayService.saveVideoSpotlight(formData).subscribe(() => {
        // Reset form
        this.resetSpotlightForm();
        this.getSpotlightsList(this.limit, this.page, this.search);
      });
    }
  }

  /**
   * Called when component being destroyed
   */
  ngOnDestroy() {
    // Clean up spotlight form value changes subscription
    if (this.spotlightFormValueChangesSubscription) {
      this.spotlightFormValueChangesSubscription.unsubscribe();
    }
  }

}
