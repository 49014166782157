import { Component, OnInit } from '@angular/core';
import { dashboard } from '../../models/dashboard';
import { dashboardEmailService } from '../../services/dashboard';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { PagedList } from '../../../shared';

@Component({
  selector: 'tga-feedbacks',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class dashboardComponent implements OnInit {
  responseData: object = {};
  startDate: NgbDate | String;
  endDate: NgbDate | String;

  constructor(private dashboardEmailService: dashboardEmailService, private calendar: NgbCalendar) {
    this.startDate = '';
    this.endDate = '';
  }

  // Handle start date selection
  onStartDateSelect(date: NgbDate) {
    this.startDate = this.formatDate(date);
    this.getDownloadEmails();

  }

  // Handle end date selection
  onEndDateSelect(date: NgbDate) {
    this.endDate = this.formatDate(date);
    this.getDownloadEmails();

  }

  formatDate(date: NgbDate): string {
    if (!date) return null;
    const year = date.year.toString().padStart(4, '0');
    const month = date.month.toString().padStart(2, '0');
    const day = date.day.toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  }

  /**
   * Called when component being called
   */
  ngOnInit() {
    // Get feedbacks list
    this.getDownloadEmails();
  }

  /**
   * Get feedbacks list
   */
  getDownloadEmails() {

    // Get feedback list by using feedback service
    console.log('this.startDate, this.endDate: ', this.startDate, this.endDate);
    this.dashboardEmailService.getDashboardCounts(this.startDate, this.endDate).subscribe((res: PagedList<dashboard[]>) => {
      this.responseData = { ...res.data }
    });
  }


}
