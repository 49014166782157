import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { UserDetail } from '../../models/users';
import { PagedList } from '../../../shared';

@Component({
  selector: 'tga-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {

  userId: string = ""; // To store user id from url
  user: UserDetail = new UserDetail(); // To store user detail

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService) {
    // Get id from url and bind it
    this.userId = this.activatedRoute.snapshot.params["id"];
  }

  /**
   * Called when component being called
   */
  ngOnInit() {
    this.getUserDetail(this.userId);
  }

  /**
   * Get user detail
   * @param userId userId for which user detail
   */
  getUserDetail(userId: string) {
    this.userService.getUserDetail(userId).subscribe((res: PagedList<UserDetail[]>) => {
      this.user = res.data[0];
    });
  }

}
