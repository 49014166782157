import { NgModule } from '@angular/core';
import { dashboardComponent } from './components/dashboard/dashboard.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [dashboardComponent],
  imports: [
    SharedModule
  ]
})
export class dashboardModule { }
