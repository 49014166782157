import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

@Component({
  selector: 'tga-add-pathway',
  templateUrl: './add-pathway.component.html',
  styleUrls: ['./add-pathway.component.scss']
})
export class AddPathwayComponent implements OnInit {

  pathwayForm: FormGroup; // To store pathway form reference
  upskills: string[] = ['book', 'podcast', 'website']; // To store upskills and should be matched with upskill group inside form
  isPathwayFormSubmitted: boolean = false; // To store status about pathway form submission
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.buildPathwayForm();
  }

  /**
   * Build pathway form
   */
  buildPathwayForm() {
    this.pathwayForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      image: [''],
      videoLength: [{ hour: 0, minute: 0, second: 0}],
      preferences: this.formBuilder.group({
        content: this.formBuilder.array([
          this.formBuilder.group({
            id: [],
            range: [0]
          }),
          this.formBuilder.group({
            id: [],
            range: [0]
          }),
          this.formBuilder.group({
            id: [],
            range: [0]
          }),
          this.formBuilder.group({
            id: [],
            range: [0]
          })
        ]),
        sources: this.formBuilder.array([
          this.formBuilder.group({
            id: [],
            range: [0]
          }),
          this.formBuilder.group({
            id: [],
            range: [0]
          }),
          this.formBuilder.group({
            id: [],
            range: [0]
          }),
          this.formBuilder.group({
            id: [],
            range: [0]
          }),
          this.formBuilder.group({
            id: [],
            range: [0]
          })
        ]),
        topic: [null]
      }),
      videos: this.formBuilder.array([
        this.buildYoutubeVideoDetailControls()
      ]),
      upskills: this.formBuilder.group({
        book: this.formBuilder.array([
          this.buildUpskillDetailControls()
        ]),
        podcast: this.formBuilder.array([
          this.buildUpskillDetailControls()
        ]),
        website: this.formBuilder.array([
          this.buildUpskillDetailControls()
        ])
      })
    });
  }

  /**
   * Build youtube video detail controls
   */
  buildYoutubeVideoDetailControls(): FormGroup {
    return this.formBuilder.group({
      title: [],
      description: [],
      tag: [null]
    }) as FormGroup;
  }

  /**
   * Build upskill detail controls
   */
  buildUpskillDetailControls(): FormGroup {
    return this.formBuilder.group({
      title: [],
      URL: [],
    }) as FormGroup;
  }

  /**
   * Add more video detail controls
   */
  addMoreVideoDetailControls() {
    (this.pathwayForm.get("videos") as FormArray).push(this.buildYoutubeVideoDetailControls());
  }

  /**
   * Add more upskill detail controls
   * @param controlName controlName for which control
   */
  addMoreUpskillDetailControls(controlName: string) {
    (this.pathwayForm.get("upskills").get(controlName) as FormArray).push(this.buildUpskillDetailControls());
  }

  /**
   * Remove youtube video detail controls
   * @param index index for which index
   */
  removeYoutubeVideoDetailControls(index: number) {
    (this.pathwayForm.get("videos") as FormArray).removeAt(index);
  }

  /**
   * Remove upskill detail controls
   * @param index index for which index
   * @param controlName controlName for which control
   */
  removeUpskillDetailControls(index: number, controlName: string) {
    (this.pathwayForm.get("upskills").get(controlName) as FormArray).removeAt(index);
  }

  /**
   * Called when pathway form when form gets submitted
   * @param isPathwayFormSubmitted isPathwayFormSubmitted to check whether pathway form is valid or not
   */
  onSubmit(isPathwayFormSubmitted: boolean) {
    console.log("isPathwayFormSubmitted :: ", isPathwayFormSubmitted);
    if (isPathwayFormSubmitted) {

    }
  }

}
