import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PathwaysService } from '../../services/pathways.service';
import { PagedList } from '../../../shared';
import { Subscription } from 'rxjs';
import { ValidationService } from '../../../core/services/validation.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'tga-video-hot-spot',
  templateUrl: './video-hot-spot.component.html',
  styleUrls: ['./video-hot-spot.component.scss']
})
export class VideoHotSpotComponent implements OnInit, OnDestroy {
  url:'';
  text:'';
  videoForm: FormGroup; // To represent video form
  isVideoFormSubmitted: boolean = false; // To store status of video form submission
  videoFormValidationMessages = { // To store pathway form validation
    'videoId': {
      'required': 'Video should be selected.',
    },
    'url': {
      'required': 'URL should not be empty.'
    },
    'type': {
      'required': 'Comment type should be selected.'
    },
    'text': {
      'required': 'Comment should not be empty.'
    },
    // 'commentCount': {
    //   'required': 'Comment count should not be empty.'
    // }
  };
  startTimeErrorMessage: string = ""; // To store start time error message
  endTimeErrorMessage: string = ""; // To store end time error message
  videoFormErrors = {
    videoId: '',
    url: '',
    type: '',
    text: ''
  }; // To store errors
  videoFormValueChangesSubscription: Subscription;
  hideVideoForm: boolean = true; // To hide show video form
  videoDetail: any = {}; // To store videos
  totalVideoSeconds: number = 0; // To store total video seconds
  totalCount: number; // To store total users list count
  videoId: string = ""; // To store video id from the route
  startTime = {}; // To store start time
  endTime = {}; // To store end time
  hotSpots: any[]; // To store video hotspots
  commentTypes: any[] = [ // To store comment type list  
    { name: 'Questions', type: 'QUESTION' },
    { name: 'Suggestions', type: 'SUGGESTION' },
    { name: 'Discuss', type: 'DISCUSS' },
    { name: 'Recommendations', type: 'RECOMMEND' }
  ];
  page: number = 1; // To store current page
  limit: number = 20; // To store current page size, default 20 
  constructor(
    private formBuilder: FormBuilder,
    private pathwayService: PathwaysService,
    private _validation: ValidationService,
    private activatedRoute: ActivatedRoute,
    private toasterService: ToastrService
  ) {
    this.videoId = this.activatedRoute.snapshot.params["videoId"];
  }

  ngOnInit() {
    // build video form
    this.buildVideoHotSpotForm();
    // Get video hotspots
    this.getHotSpots();
    // Get videos list
    this.getVideoDetail();
  }

  /**
   * Called when page index is changed
   * @param page page to be changed
   */
   onPaginationChange(page: number) {
    this.getHotSpots();
  }

  // Build video form
  buildVideoHotSpotForm() {
    this.videoForm = this.formBuilder.group({
      _id: [null],
      videoId: [this.videoId, Validators.required],
      startTime: [null],
      endTime: [null],
      url: ['', Validators.required],
      type: [null, Validators.required],
      text: ['', Validators.required],
      // commentCount: [null, Validators.required]
    });

    this.getValidationErrors();
    // this.videoFormValueChangesSubscription = this.videoForm.valueChanges.subscribe(() => this.getValidationErrors());

    // Get validation error when video form values get changed
    this.videoFormValueChangesSubscription = this.videoForm.valueChanges.subscribe((hotSpot) => {
      const { startTime, endTime } = hotSpot;
      if (startTime) {
        let startTimeInSecond = this.convertTimeToSecond(startTime);
        if (isNaN(startTimeInSecond)) {
          this.startTimeErrorMessage = "Start time should not be empty.";
        }
        else {
          this.startTimeErrorMessage = "";
        }
      }
      if (endTime) {
        let endTimeInSecond = this.convertTimeToSecond(endTime);
        if (isNaN(endTimeInSecond)) {
          this.endTimeErrorMessage = "End time should not be empty.";
        }
        else {
          this.endTimeErrorMessage = "";
        }
      }
      this.getValidationErrors()
    });
  }

  /**
   * Get hot spots
   */
  getHotSpots() {
    this.pathwayService.getVideoHotSpots(this.videoId).subscribe((res: any) => {
      this.hotSpots = res.data;
      for (let hotSpot of this.hotSpots) {
        hotSpot.startTimeFormat = this.convertSecondToHMS(hotSpot.startTime);
        hotSpot.endTimeFormat = this.convertSecondToHMS(hotSpot.endTime);
      }
    });
  }

  // Get validation errors
  getValidationErrors() {
    this.videoFormErrors = this._validation.getValidationErrors(this.videoForm, this.videoFormValidationMessages);
  }

  /**
   * Get video detail
   * @param limit limit to be displayed records
   * @param page page for which page users list
   * @param search search to be filtered
   */
  getVideoDetail() {
    this.pathwayService.getVideoDetail(this.videoId).subscribe((data: PagedList<any[]>) => {
      this.videoDetail = data.data[0];
      this.totalVideoSeconds = this.convertTimeToSecond(this.videoDetail.duration);
    });
  }

  /**
   * Convert time to second
   * @param time time to be converted
   */
  convertTimeToSecond(time: any) {
    let timeInString = `${time.hour}:${time.minute}:${time.second}`;
    let splitTime = timeInString.split(':'); // split it at the colons

    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    var seconds = (+splitTime[0]) * 60 * 60 + (+splitTime[1]) * 60 + (+splitTime[2]);
    return seconds;
  }

  /**
   * Convert second to hms
   * @param second
   */
  convertSecondToHMS(second: number) {
    second = Number(second);
    let hour = Math.floor(second / 3600);
    let minute = Math.floor(second % 3600 / 60);
    let secondTime = Math.floor(second % 3600 % 60);
    return { hour, minute, second: secondTime };
  }

  /**
   * Show video detail into form
   * @param video video to be updated
   */
  showVideoDetailToForm(video) {
    // Bind video detail in form
    this.videoForm.patchValue({
      _id: video._id,
      // commentCount: video.commentCount,
      startTime: this.convertSecondToHMS(video.startTime),
      endTime: this.convertSecondToHMS(video.endTime),
      videoId: this.videoId,
      url: video.url,
      type: video.type,
      text: video.text
    });
    this.hideVideoForm = false;
    window.scroll(0, 0); // Scroll to top
  }

  /**
   * Called when cancel button gets clicked
   */
  onCancelButtonClick() {
    this.resetVideoForm();
  }

  /**
   * Reset video form
   */
  resetVideoForm() {
    this.videoForm.reset();
    this.videoForm.patchValue({ videoId: this.videoId });
    this.isVideoFormSubmitted = false;
    this.hideVideoForm = true;
  }

  /**
   * Called when video form gets submitted
   * @param isVideoFormValid isVideoFormValid to check whether video form is valid or not
   */
  onSubmit(isVideoFormValid: boolean) {
    this.isVideoFormSubmitted = true; // Indicate that form is submitted
    const { startTime, endTime } = this.videoForm.value;
    let startTimeInSecond;
    let endTimeInSecond;
    let isValidate: boolean = true;
    if (startTime) {
      startTimeInSecond = this.convertTimeToSecond(startTime);
      if (isNaN(startTimeInSecond)) {
        this.startTimeErrorMessage = "Start time should not be empty.";
        isValidate = false;
      }
      else if (startTimeInSecond >= this.totalVideoSeconds) {
        this.startTimeErrorMessage = "Start time should not be bigger that video duration.";
        isValidate = false;
      }
    }

    if (endTime) {
      endTimeInSecond = this.convertTimeToSecond(endTime);
      if (isNaN(endTimeInSecond)) {
        this.endTimeErrorMessage = "End time should not be empty.";
        isValidate = false;
      }
      else if (endTimeInSecond >= this.totalVideoSeconds) {
        this.endTimeErrorMessage = "End time should not be bigger than video duration.";
        isValidate = false;
      }
    }

    if (isValidate) {
      if (startTimeInSecond > endTimeInSecond) {
        this.toasterService.error("Start time should not be bigger than End time");
        isValidate = false;
      }
      if (isVideoFormValid) {
        const { videoId, commentCount, _id, url, type, text } = this.videoForm.value;
        let hotSpot = {
          _id,
          videoId,
          startTime: startTimeInSecond,
          endTime: endTimeInSecond,
          url,
          type,
          text,
          commentCount
        };
        this.pathwayService.saveVideoHotSpot(hotSpot).subscribe((res: any) => {
          this.resetVideoForm();
          this.getHotSpots();
        });
      }
    }
  }

  /**
   * Delete hot spot
   * @param hotSpotId hotSpotId to be deleted
   */
  deleteHotSpot(hotSpotId: string) {
    if (confirm("Are you sure you want to delete this hot spot?")) {
      this.pathwayService.deleteVideoHotSpot(hotSpotId).subscribe(() => {
        this.getHotSpots();
      });
    }
  }

  /**
   * Called when component being destroyed
   */
  ngOnDestroy() {
    // clean up pathway form value changes subscription
    if (this.videoFormValueChangesSubscription) {
      this.videoFormValueChangesSubscription.unsubscribe();
    }
  }
}
