import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ValidationService } from '../../../core/services/validation.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'tga-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  changePasswordForm: FormGroup; // To store form reference
  changePasswordFormValidationMessages = { // To store reset password form validation
    'currentPassword': {
      'required': 'Current Password should not be empty.',
      'pattern': ''
    },
    'newPassword': {
      'required': 'New Password should not be empty.',
      'pattern': ''
    },
    'reEnterPassword': {
      'required': 'Re-Enter Password should not be empty.',
      'compare': 'New password & re-enter password mismatches'
    }
  };
  changePasswordFormErrors = {
    currentPassword: '',
    newPassword: '',
    reEnterPassword: ''
  }; // To store errors
  isChangePasswordFormSubmitted: boolean = false; // To store status about reset password form submission
  changePasswordFormValueChangesSubscription: Subscription;
  newPasswordPatternValidationMessages: string[] = []; // To store new password validation messages

  constructor(
    private formBuilder: FormBuilder,
    private _validation: ValidationService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router) {
  }

  /**
   * Called when comopnent being initialized
   */
  ngOnInit() {
    this.buildChangePasswordForm();
  }

  /** Build change password form */
  private buildChangePasswordForm() {
    this.changePasswordForm = this.formBuilder.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', [Validators.required, Validators.pattern(this._validation.regex.password)]],
      reEnterPassword: ['', Validators.required]
    },
      { validators: this._validation.comparePassword("newPassword", "reEnterPassword") }
    );

    // Get validation errors
    this.getValidationErrors();

    // Get validation error when reset password form values get changed
    this.changePasswordFormValueChangesSubscription = this.changePasswordForm.valueChanges.subscribe((resetPassword) => {
      // If new password is available then check its value contains specific pattern or not
      if (resetPassword.newPassword != "" && resetPassword.newPassword != undefined)
        this.newPasswordPatternValidationMessages = this._validation.getPasswordPatternValidationMessages(resetPassword.newPassword);
      else
        this.newPasswordPatternValidationMessages = [];
      this.getValidationErrors()
    });
  }

  // Get validation errors
  getValidationErrors() {
    this.changePasswordFormErrors = this._validation.getValidationErrors(this.changePasswordForm, this.changePasswordFormValidationMessages);
  }

  /**
   * Called when change password form is submitted
   * @param isChangePasswordFormValid isChangePasswordFormValid to check whether change password form is valid or not
   */
  onSubmit(isChangePasswordFormValid) {
    this.isChangePasswordFormSubmitted = true; // Indicate that form is submitted

    // Change password if change password form is valid
    if (isChangePasswordFormValid) {
      // Change password
      this.authService.changePassword(this.changePasswordForm.value.currentPassword, this.changePasswordForm.value.newPassword).subscribe(() => {
        this.changePasswordForm.reset();
        this.isChangePasswordFormSubmitted = false;
      });
    }

  }

  /**
   * Called when component being destroyed
   */
  ngOnDestroy() {
    // clean up change password form value changes subscription
    if (this.changePasswordFormValueChangesSubscription) {
      this.changePasswordFormValueChangesSubscription.unsubscribe();
    }
  }

}
