import { Injectable } from '@angular/core';
import { FormGroup, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  public regex = {
    // Email must contain @, domain name, .(dot)
    email: '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$',

    /*
     * Following are the creteria to validate password
     * Passwords must contain at least 6 characters & a maximum of 25 characters
     * At least one uppercase letter (A-Z)
     * At least one lowercase letter (a-z)
     * At least one special character (!,@,#,$,%,^,&,*,etc)
     * At least one digit (0-9)
     */
    password: '^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,26}$'
  }

  /**
   * Get validation errors
   * @param group group for which form group
   * @param validationMessages validationMessages
   */
  getValidationErrors(group: FormGroup, validationMessages: Object): any {
    var formErrors = {};

    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);

      formErrors[key] = '';
      if (abstractControl && !abstractControl.valid) {

        const messages = validationMessages[key];

        for (const errorKey in abstractControl.errors) {
          if (errorKey) {
            formErrors[key] += messages[errorKey] + ' ';
          }
        }
      }

      if (abstractControl instanceof FormGroup) {
        let groupError = this.getValidationErrors(abstractControl, validationMessages);
        formErrors = { ...formErrors, ...groupError }
      }

    });
    return formErrors
  }

  /**
   * Get password pattern validation messages
   * @param passwordValue passwordValue to be checked
   */
  getPasswordPatternValidationMessages(passwordValue: string): string[] {
    let messages: string[] = [];
    if (passwordValue.length < 8 || passwordValue.length > 25)
      messages.push('Password must contain at least 6 characters & a maximum of 25 characters');
    if (!passwordValue.match(/^.*[!*@#$%^&+=\d]/g))
      messages.push('Password must contain at least one special character (!,@,#, $,%,^,&,*, etc)');
    if (!passwordValue.match(/[A-Z]/g))
      messages.push('Password must contain at least one uppercase letter (A-Z)');
    if (!passwordValue.match(/[a-z]/g))
      messages.push('Password must contain at least one lowercase letter (a-z)');
    if (!passwordValue.match(/[0-9]/g))
      messages.push('Password must contain at least one digit (0-9)');
    return messages;
  }

  /**
   * Compare password
   * @param password password
   * @param confirmPassword confirmPassword
   */
  comparePassword(password: string, confirmPassword: string): object {
    return (control: AbstractControl) => {
      //Get new password and re-enter password value
      let newPasswordValue = control.get(password).value;
      let reEnterPasswordValue = control.get(confirmPassword).value;

      //Check if both value available then compare
      if (newPasswordValue && reEnterPasswordValue) {
        //Check if both value are not same then set error on re-enter password and return form error with compare false
        //Otherwise set null in re-enter password error
        if (newPasswordValue !== reEnterPasswordValue) {
          control.get(confirmPassword).setErrors({ 'compare': true });
          return { 'compare': true };
        }
        control.get(confirmPassword).setErrors(null);
      }
      return null;
    }
  }

  /**
   * Get form data from reactive form
   * @param formGroup formGroup for which form you wanna build form data
   * @param formData formData formData to added key value pair
   * @param parentFormKey parentFormKey parentFormKey to be concated in key with child key
   */
  getFormDataFromFormGroup(formGroup: FormGroup, formData: FormData, defaultKey: string = "", parentFormKey: string = ""): FormData {
    Object.keys(formGroup.controls).forEach((key: string) => {
      // Get a reference to the control using the FormGroup.get() method
      const abstractControl = formGroup.get(key);

      // If the control is an instance of FormGroup i.e a nested FormGroup
      // then recursively call this same method (getFormDataFromFormGroup) passing it
      // the FormGroup so we can get to the form controls in it

      if (abstractControl instanceof FormGroup) {
        key = parentFormKey ? `${parentFormKey}.${key}` : key;
        formData = this.getFormDataFromFormGroup(abstractControl, formData, defaultKey, key);
        // If the control is not a FormGroup then we know it's a FormControl
      }
      else {
        //Remove those key which have undedined value 
        if (abstractControl.value != undefined) {
          let defaultArrayKey = defaultKey ? defaultKey : '';
          key = parentFormKey ? `${defaultArrayKey}${parentFormKey}.${key}` : `${defaultArrayKey}${key}`;
          formData.append(key, abstractControl.value);
        }
      }

    });
    return formData;
  }

  /**
   * Validate image type extension
   * @param control control to be checked
   */
  static validateImageTypeExtension(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      let imageTypes = ["image/png", "image/jpg", "image/jpeg"];
      if (!imageTypes.includes(control.value.type))
        return { 'invalidType': true };
    }
    return null;
  }

  static validateImageSize(maxSize: number): ValidatorFn | null {
    return (control: AbstractControl) => {
      if (control.value) {
        if (control.value.size <= maxSize) {
          return null
        }
        else {
          return { 'invalidSize': true };
        }
      }
      return null;
    }
  }
}
