import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from '../services/loader.service';
import { Router } from '@angular/router';

@Injectable()
export class HttpAPIInterceptor implements HttpInterceptor {
  private requests: string[] = []; //To store requests which are being executed
  constructor(
    private router: Router,
    private toasterService: ToastrService,
    private loaderService: LoaderService
  ) {
  }

  /**
   * Remove url fom requests list after executing
   * @param url
   */
  removeRequest(url: string) {
    const i = this.requests.indexOf(url);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  /**
   * Intercept request
   * @param req req to be modified
   * @param next next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    //Show loader
    this.loaderService.isLoading.next(true);
    //Get auth token, pass it as bearer in reuest if its available
    let token = localStorage.getItem("auth-token");
    // To store status about passing header
    let hideHeader = req.params.get("hideHeader");
    // Auth token available and header should be passed then pass the header
    if (token && hideHeader != "true") {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    //Handle the request and response
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        //remove url from requests
        this.removeRequest(req.url);
        // Show message in toaster
        if (event.body.message) {
          this.toasterService.success(event.body.message);
        }
      }
    },
      (error: any) => {
        //remove url from requests
        this.removeRequest(req.url);
        // Show error message in toaster
        if (error.error && error.error.message) {
          this.toasterService.error(error.error.message);
        }
        if (error.status == 0 || error.status == -1) {
          this.toasterService.error("Server Not Responding");
        }
        else if (error.status == 401) {             
          localStorage.clear();                
          this.router.navigate(['login']);         
        }
        else if (error.status == 403) {
          this.router.navigate(['/unauthorized']);          
        }
        else if (error.status == 404) {
          this.toasterService.error("Resource You Have Requested Not Found," + " URL: " + error.url);
        }
      }));
  }
}
