import { Component, OnInit, OnDestroy } from '@angular/core';
import { SlicePipe } from '@angular/common';
import { Pathways } from '../../models/pathways';
import { PathwaysService } from '../../services/pathways.service';

interface Filter {
  plan?: string; // Optional filter for plan type
  fromDate?: string; // Optional filter for start date
  endDate?: string; // Optional filter for end date
}

interface Pagination {
  page: number;
  limit: number;
  totalCount?: number; // Optional property for total count
}

@Component({
  selector: 'tga-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
  providers: [SlicePipe] 
})
export class SubscriptionsComponent implements OnInit, OnDestroy {
  allSubscriptions: Pathways[] = [];
  subscriptions: Pathways[]; // To store subscriptions list
  subscriptionTypes: any[] = ["Free", "Curator (Monthly) Plan", "Curator (Yearly) Plan"]; // To store subscription types
  search: string = ''; // To store search text
  myFilter: Filter = {
    plan: '',
    fromDate: '',
    endDate: ''
  };
  //PAGINATION RELATED VARIABLES
  pagination: Pagination = {
    page: 1,
    limit: 10,
    totalCount: 0
  };
  freeCount: number = 0;
  curatorMonthlyCount: number = 0;
  curatorYearlyCount: number = 0;
  constructor( 
    private pathwaysService: PathwaysService,
    private slicePipe: SlicePipe
  ) { }

  /**
   * Called when component being called
   */
  ngOnInit() {
    // Get subscriptions list
    this.getSubscriptions(this.search, this.myFilter);
  }

  /**
   * Get subscriptions list
   * @param search
   * @param limit limit to be displayed records
   * @param page page for which page subscriptions list
   */
  getSubscriptions(search: string, myFilter: any) {
    let filter = {
      plan: myFilter.plan,
      fromDate : myFilter.fromDate ? this.formatDate(myFilter.fromDate) : '',
      endDate : myFilter.endDate ? this.formatDate(myFilter.endDate) : '',
    }

    // Get pathway list by using users service
    this.pathwaysService.getSubscriptionsList(search, filter).subscribe((res: any) => {
      if(search || filter.plan || filter.fromDate || filter.endDate) {
        this.pagination.page = 1;
      }
      let data = res.data;

      this.curatorMonthlyCount = 0;
      this.curatorYearlyCount = 0;
      this.freeCount = 0;

      for (const item of data) {
        const validity = item.validity.toLowerCase(); // Convert validity to lowercase for case-insensitive matching
        if (validity && validity.includes("month")) {
          this.curatorMonthlyCount += 1;
        } else if (validity && validity.includes("year")) {
          this.curatorYearlyCount += 1;
        } else {
          this.freeCount += 1;
        }
      }
      this.allSubscriptions = data;
      this.updateDisplayedSubscriptions();
    });
  }

  formatDate(dateObject) {
    const year = String(dateObject.year).padStart(4, '0');
    const month = String(dateObject.month).padStart(2, '0');
    const day = String(dateObject.day).padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }

  /**
   * Called when performed search
   * @param search search to be filtered
   */
  onSearchSubmit(search: string) {
    this.getSubscriptions(this.search, this.myFilter);
  }
  
  onFilterSubmit() {
    this.getSubscriptions(this.search, this.myFilter);
  }

  resetFilter() {
    this.search = '';
    this.myFilter = {
      plan: '',
      fromDate: '',
      endDate: ''
    };   
    this.getSubscriptions(this.search, this.myFilter);
  }

  /**
   * Called when page index is changed
   * @param page page to be changed
   */
  // onPaginationChange(page: number) {
  //   this.pagination.page = page;
  //   this.getSubscriptions(this.search, this.myFilter, this.pagination);
  // }

  onPaginationChange(page: number) {
    this.pagination.page = page;
    this.updateDisplayedSubscriptions();
  }

  updateDisplayedSubscriptions() {
    const startIndex = (this.pagination.page - 1) * this.pagination.limit;
    const endIndex = startIndex + this.pagination.limit;

    this.subscriptions = this.slicePipe.transform(this.allSubscriptions, startIndex, endIndex);
    this.pagination.totalCount = this.allSubscriptions.length;
  }

  /**
   * Called when component being destroyed
   */
  ngOnDestroy() {
    // clean up pathway form value changes subscription
  }
}
