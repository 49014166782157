import { Injectable } from '@angular/core';
import { ContactRequests } from '../models/contact-requests';
import { PagedList } from '../../shared';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactRequestsService {

  constructor(private http: HttpClient) { }

  /**
   * Get feedbacks list
   */
  getContactRequestsList(search: string, limit: number, page: number) {
    // Initialize parameters
    let params = new HttpParams().set("search", search).set("limit", limit.toString()).set("page", page.toString());
    return this.http.get<PagedList<ContactRequests[]>>(`${environment.apiUrl}dashboard/getContactRequests`, { params });
  }
}
