import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PagedList } from '../../shared';
import { FlaggedComments } from '../models/flagged-comments';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FlaggedCommentsService {

  constructor(private http: HttpClient) { }

  /**
   * Get flagged comments list
   * @param limit limit to display total records
   * @param page page for which page index records
   */
  getFlaggedCommentsList(limit: number, page: number, pathwayId: string, type: string) {
    let params = new HttpParams().set("pathwayId", pathwayId)
    if (type == "video") {
      // Initialize parameters
      params
        .set("limit", limit.toString())
        .set("page", page.toString())
        .set("isFlagged", "true");
      return this.http.get(`${environment.apiUrl}dashboard/video/getVideoCommentsDashboard`, { params });
    }
    else {
      return this.http.get(`${environment.apiUrl}dashboard/getForumFlaggComment`, { params });
    }

  }

  /**
   * Delete flagged comment
   * @param id id for which flagged comment to be deleted
   */
  deleteFlaggedComment(id: string) {
    return this.http.delete(`${environment.apiUrl}flagged-comment/delete/${id}`);
  }

  /**
   * Delete forum flagged comment
   * @param id id to be deleted
   */
  deleteForumFlaggedComment(comment: any) {
    // return this.http.post(`${environment.apiUrl}dashboard/archiveForumComment?_id=${id}`, {});
    return this.http.post(`${environment.apiUrl}dashboard/archiveForumComment`, comment);
  }

  /**
   * Repost flagged comment
   * @param repostComment repostComment for which flagged comment to be reposted
   */
  repostForumFlaggedComment(repostComment: any) {
    return this.http.post(`${environment.apiUrl}dashboard/updateForumAnswer`, repostComment);
  }

  /**
   * Repost or archive video flagged comment
   * @param comment comment to be reposted or archive
   */
  repostOrArchiveVideoFlaggedComment(comment: any) {
    return this.http.put(`${environment.apiUrl}dashboard/video/updateVideoCommentsDashboard`, comment);
  }
}
