import { Component, OnInit } from '@angular/core';
import { FlaggedComments } from '../../models/flagged-comments';
import { PagedList } from '../../../shared';
import { FlaggedCommentsService } from '../../services/pathways.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'tga-flagged-comments',
  templateUrl: './flagged-comments.component.html',
  styleUrls: ['./flagged-comments.component.scss']
})
export class FlaggedCommentsComponent implements OnInit {

  flaggedComments: FlaggedComments[]; // To store flagged comments list
  page: number = 1; // To store current page
  limit: number = 20; // To store current page size, default 20
  totalCount: number; // To store total flagged comments list count
  /** flag type list */
  flagTypeList = [
    { name: 'Video Dashboard Flagged Comments', value: 'video' },
    { name: 'Forum Flagged Answers', value: 'forum' }
  ];
  currentType: string = "video";
  pathwayId: string = ""; // To store pathway id
  constructor(
    private flaggedCommentsService: FlaggedCommentsService,
    private activatedRoute: ActivatedRoute) {
    this.pathwayId = this.activatedRoute.snapshot.params["pathwayId"];
  }

  /**
   * Called when component being called
   */
  ngOnInit() {
    // Get flagged comments list
    this.getFlaggedComments(this.limit, this.page, this.currentType);
  }

  /**
   * Get flagged comments list
   * @param limit limit to be displayed records
   * @param page page for which page flagged comments list
   */
  getFlaggedComments(limit: number, page: number, type: string) {
    // Get flagged comments list by using flagged comment service
    this.flaggedCommentsService.getFlaggedCommentsList(limit, page, this.pathwayId, type).subscribe((res: any) => {
      this.flaggedComments = res.data;
      this.totalCount = res.totalCount;
    });
  }

  /**
   * Called when page gets changed
   * @param page page to be changed
   */
  onPaginationChange(page: number) {
    this.page = page;
    this.getFlaggedComments(this.limit, page, this.currentType);
  }

  /**
   * Called when flag type selection gets changed
   * @param event
   */
  onChangeFlagTypeSelection(event) {
    this.currentType = event.target.value;
    this.page = 1;
    this.getFlaggedComments(this.limit, this.page, this.currentType);
  }

  /**
   * Delete flagged comment
   * @param id id for which flagged comment to be deleted
   * @param title title to be displayed in confirmation
   */
  deleteFlaggedComment(id: string, answerId:string, flaggedComment: any) {
    // Take confirmation from user and delete pathway according to confirmation
    if (confirm(`Are you sure you want to archive '${flaggedComment.text}' flagged comment?`)) {
      // Delete flagged comment and get flagged comments list
      if (this.currentType == "video") {
        let comment = {
          "commentId": flaggedComment._id,
          "replyId": flaggedComment.replyId,
          "isArchive": true
        };
        // Archive flagged video comment
        this.flaggedCommentsService.repostOrArchiveVideoFlaggedComment(comment).subscribe(() => {
          this.getFlaggedComments(this.limit, this.page, this.currentType);
        });
      }
      else {
        let comment = {
          "commentId": id,
          "answerId":answerId,
        };

        this.flaggedCommentsService.deleteForumFlaggedComment(comment).subscribe(() => {
          this.getFlaggedComments(this.limit, this.page, this.currentType);
        });
      }
    }
  }

  /**
   * Repost flagged comment
   * @param id id for which flagged comment to be reposted
   * @param title title to be displayed in confirmation
   */
  repostFlaggedComment(id: string, answerId:string, flaggedComment: any) { 
    // Take confirmation from user and delete pathway according to confirmation
    if (confirm(`Are you sure you want to repost '${flaggedComment.text}' flagged comment?`)) {
      if (this.currentType == "video") {
        let comment = {
          "commentId": flaggedComment._id,
          "replyId": flaggedComment.replyId,
          "isFlag": true
        };
        // Repost flagged video comment
        this.flaggedCommentsService.repostOrArchiveVideoFlaggedComment(comment).subscribe(() => {
          this.getFlaggedComments(this.limit, this.page, this.currentType);
        });
      }
      else {
        let comment = {
          _id: id,
          answerId:answerId,
          isflag: false
        };
        // Repost flagged comment and get flagged comments list
        this.flaggedCommentsService.repostForumFlaggedComment(comment).subscribe(() => {
          this.getFlaggedComments(this.limit, this.page, this.currentType);
        });
      }
    }
  }

}
