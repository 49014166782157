import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GuestGuard implements CanActivate {

  constructor(
    private router: Router
  ) { }

  /**
   * Check whether route can active or not based on user authentication
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // Get current user from local storage
    const currentUser = JSON.parse(localStorage.getItem("loggedInUser"));

    // Check if current user is not available then access the url whichever
    // Otherwise redirect to user list
    if (!currentUser) {
      return true;
    }
    this.router.navigate(['/user/list']);
    return false;
  }
}
