import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

// Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { AuthModule } from './auth/auth.module';
import { UsersModule } from './users/users.module';
import { PathwaysModule } from './pathways/pathways.module';
import { FlaggedCommentsModule } from './flagged-comments/flagged-comments.module';
import { FeedbackModule } from './feedback/feedback.module';
import { ContactRequestsModule } from './contact-requests/contact-requests.module'
import { AdminModule } from './admin/admin.module';
import { TopVoiceModule } from './top-voice/top-voice.module';
import { downloadAppEmailModule } from './downloadAppEmail/downloadAppEmail.module'
import { dashboardModule } from './dashboard/dashboard.module';

// Components
import { AppComponent } from './app.component';

// Interceptors
import { HttpAPIInterceptor } from './core';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({ positionClass: 'toast-top-right', closeButton: true, timeOut: 6000 }), //Display toaster at bottom right position with close icon
    AppRoutingModule,
    CoreModule,
    AuthModule,
    UsersModule,
    PathwaysModule,
    FlaggedCommentsModule,
    FeedbackModule,
    ContactRequestsModule,
    downloadAppEmailModule,
    dashboardModule,
    AdminModule,
    TopVoiceModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpAPIInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
