import { Component } from '@angular/core';
import { NavigationEnd, Router, Event, ActivatedRouteSnapshot, NavigationStart, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AppConfig } from './core/interfaces/app-config.interface';
import { appConfig } from './core/config/app-config.const';
import { LoaderService } from './core/services/loader.service';

@Component({
  selector: 'tga-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // app config
  config: AppConfig = appConfig;
  loading: boolean; //To show hide loader on request
  constructor(
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private loaderService: LoaderService
  ) {
    // Subscribe router event and set document title
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        // Get document title from the route data
        var title = this.getDocumentTitle(this.router.routerState.snapshot.root);
        // Join above title with app title by specified delimiter
        title = [title, this.config.appTitle].join(this.config.browserTabTitleDelimiter);
        // Set document title
        this.titleService.setTitle(`${title}`);
      }
    });

    //Subscribe isloading event to hide show loader
    this.loaderService.isLoading.subscribe((isShowLoader) => {
      this.loading = isShowLoader;
    });
  }

  /**
    * Get document title
    * @param routeSnapshot routeSnapshot to get title from route
    */
  private getDocumentTitle(routeSnapshot: ActivatedRouteSnapshot) {
    var title = routeSnapshot.data['title'];
    // Check if the first child of current route in the router state tree is available or not
    if (routeSnapshot.firstChild) {
      title = this.getDocumentTitle(routeSnapshot.firstChild) || title;
    }
    return title;
  }

}
