import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { PathwaysService, Pathways } from '../../../pathways';
import { ValidationService } from '../../../core/services/validation.service';
import { PagedList } from '../../../shared';
import { TopVoiceService } from '../../services/top-voice.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'tga-top-voices',
  templateUrl: './top-voices.component.html',
  styleUrls: ['./top-voices.component.scss']
})
export class TopVoicesComponent implements OnInit {

  topVoiceForm: FormGroup; // To represent top voice form
  isTopVoiceFormSubmitted: boolean = false; // To store status of top voice form submission
  topVoiceFormValidationMessages = { // To store top voice form validation
    'pathwayId': {
      'required': 'Pathway should not be empty.'
    },
    'name': {
      'required': 'Name should not be empty.'
    },
    'image': {
      'required': 'Profile Picture should not be empty.'
    }
  };
  topVoiceFormErrors = {
    pathwayId: '',
    name: '',
    image: '',
    instagram: '',
    twitter: '',
    facebook: '',
    website: ''
  }; // To store errors
  topVoiceImageUrl: string = ""; // To store top voice image url
  topVoiceFormValueChangesSubscription: Subscription;
  hideTopVoiceForm: boolean = true; // To hide show spotlight form
  pathways: any[] = []; // To store pathways list
  topVoices: any[]; // To store top voices list
  page: number = 1; // To store current page
  limit: number = 20; // To store current page size, default 20
  totalCount: number; // To store total users list count
  search: string = ''; // To store search text
  constructor(
    private formBuilder: FormBuilder,
    private pathwayService: PathwaysService,
    private _validation: ValidationService,
    private topVoiceService: TopVoiceService,
    private toasterService: ToastrService,
  ) { }

  /**
   * Called when component being called
   */
  ngOnInit() {
    // Build top voice form
    this.buildTopVoiceForm();

    // Get pathways list
    this.getPathways();

    // Get top voices
    this.getTopVoices(this.limit, this.page);
  }

  // Build spotlight form
  buildTopVoiceForm() {
    this.topVoiceForm = this.formBuilder.group({
      _id: [null],
      pathwayId: [null, Validators.required],
      name: ['', Validators.required],
      instagram: [''],
      twitter: [''],
      facebook: [''],
      website: [''],
      image: [null, Validators.required]
    });

    this.getValidationErrors();

    // Get validation error when spotlight form values get changed
    this.topVoiceFormValueChangesSubscription = this.topVoiceForm.valueChanges.subscribe(() => this.getValidationErrors());
  }

  // Get validation errors
  getValidationErrors() {
    this.topVoiceFormErrors = this._validation.getValidationErrors(this.topVoiceForm, this.topVoiceFormValidationMessages);
  }

  /**
   * Get pathways list
   */
  getPathways() {
    // Get pathway list by using pathway service
    this.pathwayService.getPathwaysList(20, 1).subscribe((res: PagedList<Pathways[]>) => {
      this.pathways = res.data;
    });
  }

  /**
   * Get top voices
   * @param limit
   * @param page
   * @param search
   */
  getTopVoices(limit: number, page: number, search: string = "") {
    this.topVoiceService.getTopVoices(limit, page, search).subscribe((data: any) => {
      this.topVoices = data.data;
      this.totalCount = data.totalCount;
    });
  }

  /**
   * Called when performed search
   * @param search search to be filtered
   */
  onSearchSubmit(search: string) {
    this.getTopVoices(this.limit, this.page, search);
  }

  /**
   * Show top voice detail into form
   * @param topVoice topVoice to be updated
   */
  showTopVoiceDetailToForm(topVoice) {
    this.topVoiceForm.patchValue({
      _id: topVoice._id,
      pathwayId: topVoice.pathwayId,
      name: topVoice.name,
      instagram: topVoice.instagram,
      twitter: topVoice.twitter,
      facebook: topVoice.facebook,
      website: topVoice.website,
      image: ''
    });

    this.addRemovePictureValidation(false);

    // Displayed profile if available
    if (topVoice.profile) {
      this.topVoiceImageUrl = `${environment.apiBaseUrl}${topVoice.profile}`;
    }

    // Show add edit form
    this.hideTopVoiceForm = false;
    // Scroll to top
    window.scroll(0, 0);
  }

  /**
   * Add remove profile pic validation
   * @param isAdd isAdd to be added or removed
   */
  addRemovePictureValidation(isAdd: boolean) {
    // Get picture control
    const pictureControl = this.topVoiceForm.controls['image'];
    // If need to add then add validation in picture control
    if (isAdd) {
      pictureControl.setValidators(Validators.required);
    }
    else {
      pictureControl.clearValidators();
    }

    pictureControl.updateValueAndValidity();
  }

  /**
   * Called when page index is changed
   * @param page page to be changed
   */
  onPaginationChange(page: number) {
    this.getTopVoices(this.limit, page, this.search);
  }

  /**
   * Delete top voice
   * @param topVoice topVoice to be deleted
   */
  deleteTopVoice(topVoice) {
    // Get confirmation from user about deletion of top voice
    if (confirm(`Are you sure you want to delete '${topVoice.name}' top voice?`)) {
      // Delete top voice
      this.topVoiceService.deleteTopVoice(topVoice._id).subscribe(() => {
        // Get spotlights list after deleteing spotlight video
        this.getTopVoices(this.limit, this.page, this.search);
      });
    }
  }

  /**
   * Called when cancel button gets clicked
   */
  onCancelButtonClick() {
    this.resetTopVoiceForm();
  }

  /**
   * Reset top voice form
   */
  resetTopVoiceForm() {
    this.topVoiceForm.reset();
    this.isTopVoiceFormSubmitted = false;
    this.hideTopVoiceForm = true;
    this.topVoiceImageUrl = "";
    this.addRemovePictureValidation(true);
  }

  /**
   * Called on file change
   * @param event event to get file
   */
  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.topVoiceForm.patchValue({ image: file });
      //Top Voice profile picture url
      let fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (e: any) => {
        this.topVoiceImageUrl = e.target.result;
      };
    }
  }

  /**
   * Called when top voice form gets submitted 
   * @param isTopVoiceFormSubmitted isTopVoiceFormSubmitted to check whether form is submitted or not
   */
  onSubmit(isTopVoiceFormSubmitted) {
    this.isTopVoiceFormSubmitted = true; // Indicate that form is submitted
    if (isTopVoiceFormSubmitted) {
      let { instagram, twitter, facebook, website } = this.topVoiceForm.value;
      if (instagram || twitter || facebook || website) {
        // Convert json to form data
        let formData = this._validation.getFormDataFromFormGroup(this.topVoiceForm, new FormData());
        // Save top voice
        this.topVoiceService.saveTopVoice(formData).subscribe(() => {
          // Reset form
          this.resetTopVoiceForm();
          this.getTopVoices(this.limit, this.page, this.search);
        });
      }
      else {
        this.toasterService.error("One of the URLs must be entered, either instagram, twitter, facebook or website.");
        return;
      }
    }
  }

  /**
   * Called when component being destroyed
   */
  ngOnDestroy() {
    // Clean up top voice form value changes subscription
    if (this.topVoiceFormValueChangesSubscription) {
      this.topVoiceFormValueChangesSubscription.unsubscribe();
    }
  }

}
