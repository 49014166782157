  
/**
 * Global Configuration, i.e. app title, browser tab delimiter etc.
 *
 * Shared settings for all environments: dev, prod, stage etc.
 */
import { AppConfig } from '../interfaces/app-config.interface';

export const appConfig: AppConfig = {
  appTitle: 'Tangram',
  browserTabTitleDelimiter: ' | ',
};
