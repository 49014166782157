import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { LoginVM } from '../models/auth';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  /**
   * Do login
   * @param login login to be checked
   */
  doLogin(login: LoginVM) {
    return this.http.post<any>(`${environment.apiUrl}users/cmsLogin`, login);
  }

  /**
   * forgot password
   * @param email email to be used to send reset password link
   */
  forgotPassword(email: string) {
    return this.http.post<any>(`${environment.apiUrl}users/forgotPassword`, { email });
  }

  /**
   * Reset password
   * @param password password to be changed
   * @param token token to be identified for which users password should be reset
   */
  resetPassword(password: string, token: string) {
    return this.http.post<any>(`${environment.apiUrl}users/resetPassword?token=${token}`, { password });
  }

  /**
   * Change password
   * @param currentPassword currentPassword to be verified
   * @param newPassword newPassword to be set
   */
  changePassword(currentPassword: string, newPassword: string) {
    return this.http.post<any>(`${environment.apiUrl}users/changePassword`, { currentPassword, newPassword });
  }

  /**
   * Register admin
   * @param register register to be saved
   */
  registerAdmin(register) {
    return this.http.post(`${environment.apiUrl}admin/registerAdmin`, register);
  }

  /**
   * Verify recaptcha v3 token
   * @param token token to be verified
   */
  verifyRecaptchaV3Token(token: string) {
    return this.http.get(`${environment.apiUrl}recaptcha/verify/${token}`);
  }
}
