import { Component } from '@angular/core';

@Component({
  selector: 'tga-guest-layout',
  templateUrl: './guest-layout.component.html',
  styleUrls: ['./guest-layout.component.scss']
})
export class GuestLayoutComponent {
  
}
