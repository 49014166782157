import { NgModule } from '@angular/core';
import { TopVoicesComponent } from './components/top-voices/top-voices.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [TopVoicesComponent],
  imports: [
    SharedModule
  ]
})
export class TopVoiceModule { }
