import { Injectable } from '@angular/core';
import { downloadAppEmail } from '../models/downloadAppEmail';
import { PagedList } from '../../shared';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class downloadAppEmailService {

  constructor(private http: HttpClient) { }

  /**
   * Get feedbacks list
   */
  getDownloadAppEmailsList(limit: number, page: number) {
    // Initialize parameters
    let params = new HttpParams().set("limit", limit.toString()).set("page", page.toString());
    return this.http.get<PagedList<downloadAppEmail[]>>(`${environment.apiUrl}dashboard/getDownloadAppEmail`, { params });
  }
}
