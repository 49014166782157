import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ValidationService } from '../../core/services/validation.service';
import { Subscription } from 'rxjs';
import { AdminService } from '../services/admin.service';

@Component({
  selector: 'tga-mail-invitation',
  templateUrl: './mail-invitation.component.html',
  styleUrls: ['./mail-invitation.component.scss']
})
export class MailInvitationComponent implements OnInit, OnDestroy {

  mailInvitationForm: FormGroup; // To store mail invitation form reference
  mailInvitationFormValidationMessages = { // To store mail invitation validation messages
    'firstName': {
      'required': 'First name should not be empty.'
    },
    'lastName': {
      'required': 'Last name should not be empty.'
    },
    'email': {
      'required': 'Email should not be empty.',
      'pattern': 'Email must contain @, domain name, .(dot)'
    },
  };
  mailInvitationFormErrors = {
    firstName: '',
    lastName: '',
    email: ''
  }; // To store errors
  isMailInvitationFormSubmitted: boolean = false; // To store status about login form submission
  mailInvitationFormValueChangesSubscription: Subscription;
  constructor(
    private formBuilder: FormBuilder,
    private _validation: ValidationService,
    private adminService: AdminService
  ) { }

  /**
   * Called when component being called
   */
  ngOnInit() {
    this.buildMailInvitationForm();
  }

  /**
   * Build mail invitation form
   */
  private buildMailInvitationForm() {
    this.mailInvitationForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      screenName: [''],
      email: ['', [Validators.required, Validators.pattern(this._validation.regex.email)]],
    });

    this.getValidationErrors();

    // Get validation error when mail invitation form values get changed
    this.mailInvitationFormValueChangesSubscription = this.mailInvitationForm.valueChanges.subscribe(() => this.getValidationErrors());
  }

  // Get validation errors
  private getValidationErrors() {
    this.mailInvitationFormErrors = this._validation.getValidationErrors(this.mailInvitationForm, this.mailInvitationFormValidationMessages);
  }

  /**
   * Called when mail invitation form gets submitted
   * @param isMailInvitationFormValid isMailInvitationFormValid to check whether form is valid or not
   */
  onSubmit(isMailInvitationFormValid: boolean) {
    this.isMailInvitationFormSubmitted = true; // Indicate that form is submitted
    if (isMailInvitationFormValid) {
      // Get email name part
      let emailNamePart = this.mailInvitationForm.value.email.split("@")[0];
      // Bind screen name using email name part and time stamp
      this.mailInvitationForm.patchValue({
        screenName: emailNamePart + new Date().getTime()
      });
      this.adminService.sendMailInvitationToAdminRegistration(this.mailInvitationForm.value).subscribe(() => {
        // Reset form data
        this.isMailInvitationFormSubmitted = false;
        this.mailInvitationForm.reset();
      });
    }
  }

  /**
   * Called when component being destroyed
   */
  ngOnDestroy() {
    // clean up mail invitation form value changes subscription
    if (this.mailInvitationFormValueChangesSubscription) {
      this.mailInvitationFormValueChangesSubscription.unsubscribe();
    }
  }

}
